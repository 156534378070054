import { makeAutoObservable, reaction } from 'mobx';

import agent from '../api/agent';
import { DecodedToken } from '../models/decoded-token';
import { Credential } from '../models/credential';
export default class UserStore {
	private tokenExpirationTimer: any;
	token: string | null = window.localStorage.getItem('token');
	loginUrl: string | null;

	async authenticate(credential: Credential) {
		const jwt = await agent.Auth.authenticate(credential);
		if (jwt) {
			localStorage.setItem('token', jwt);
			this.token = jwt;
			console.log('token: ' + jwt);
			this.autoLogout(86400000);
		}
	}

	constructor() {
		makeAutoObservable(this);

		reaction(
			() => this.token,
			(token) => {
				if (token) {
					localStorage.setItem('token', token);
				} else {
					localStorage.removeItem('token');
				}
			}
		);
	}
	loggedIn() {
		if (this.token) {
			let decodedToken = JSON.parse(atob(this.token.split('.')[1]));

			const expires = new Date(decodedToken.exp * 1000);

			const remainingTime = this.calculateRemainingTime(expires.toISOString());
			return remainingTime >= 0;
		}

		return false;
	}

	private calculateRemainingTime = (expirationTime: string) => {
		const currentTime = new Date().getTime();
		const adjExpirationTime = new Date(expirationTime).getTime();

		const remainingDuration = adjExpirationTime - currentTime;

		return remainingDuration;
	};

	getDecodedToken() {
		if (!this.token) return null;

		let decodedToken = JSON.parse(atob(this.token.split('.')[1]));

		let document: DecodedToken = {
			id: parseInt(decodedToken.nameid),
			type: decodedToken.unique_name,
			description: decodedToken.given_name,
			role: decodedToken.role,
			url: decodedToken.url,
		};
		return document;
	}

	logout() {
		localStorage.removeItem('token');
		localStorage.removeItem('customer');
		if (this.tokenExpirationTimer) clearTimeout(this.tokenExpirationTimer);
		this.tokenExpirationTimer = null;
	}

	autoLogout(expiration: number) {
		this.tokenExpirationTimer = setTimeout(() => {
			this.logout();
		}, expiration);
	}
}
