import { useEffect, useState } from 'react';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import MobileHomepageButton from './MobileHomepageButton';
import CollapseButton from './CollapseButton';
import { toast } from 'react-toastify';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';

type Props = {
	json: BollettaJSON;
	backToHomepage: () => void;
	setCurrentContract: (index: number) => void;
	currentContract: number;
};

const Utilities = ({
	json,
	backToHomepage,
	setCurrentContract,
	currentContract,
}: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) setIsMobile(true);
			else setIsMobile(false);
		};
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const buttonClick = (index: number, contractNumber: string) => {
		agent.Tracking.track(Static.smatChangeUtilityButtonClick + contractNumber);
		setCurrentContract(index);
		toast.dismiss();
		toast.success(
			'Attualmente visualizzando i dati relativi al contratto ' + contractNumber
		);
	};
	return json.DatiFornitura.length > 1 ? (
		<div
			className={`card border-0 mt-md-4 white-to-blue-card border-radius ${
				isMobile ? '' : 'pb-4'
			}`}
		>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					<MobileHomepageButton backToHomepage={backToHomepage} color='white' />
					<span className='h3 ms-3 mt-1'>ELENCO FORNITURE</span>
				</div>
				<CollapseButton
					section='Utilities'
					toggleFunction={setCollapseShowing}
					color='white'
					showing={collapseShowing}
				/>
			</div>
			<div
				className={`collapse ${!isMobile ? 'show' : collapseShowing && 'show'}`}
			>
				<div className='main-bg-gradient card-header'></div>
				<div className='px-4 mt-3'>
					<div className='h3 my-2 d-none d-md-block'>ELENCO FORNITURE</div>
				</div>
				{json.DatiFornitura.map((x, index) => {
					return (
						<div
							key={index}
							className={`row px-4 my-md-3 ${index === 0 ? 'my-3' : 'my-5'}`}
						>
							<div className='col-md-3'>
								{x.NumeroContratto.Etichetta}
								<div className='fw-bold'>{x.NumeroContratto.Valore}</div>
							</div>
							<div className='col-md-3'>
								{x.TipologiaUtenza.Etichetta}
								<div className='fw-bold'>{x.TipologiaUtenza.Valore}</div>
							</div>
							<div className='col-md-3'>
								{x.IndirizzoFornitura.Etichetta}
								<div className='fw-bold'>{x.IndirizzoFornitura.Valore}</div>
							</div>
							<div className='col-md-3 mt-2 mt-md-auto text-md-end'>
								<button
									className={`btn rounded-pill px-4 py-2 ${
										index === currentContract && 'disabled'
									} ${isMobile ? 'darker-button' : 'main-button'}`}
									type='button'
									onClick={() => buttonClick(index, x.NumeroContratto.Valore)}
								>
									VISUALIZZA DETTAGLIO FORNITURA
								</button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		<></>
	);
};

export default Utilities;
