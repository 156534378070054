import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Static } from '../../../../../static';
import agent from '../../../../../api/agent';
import { DocumentData } from '../../../../iveco/recall-campaign/vehicle/models/document-data';

const NewCustomer = () => {
	// const json = useLoaderData() as BollettaJSON;

	const [privacy, setPrivacy] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formSent, setFormSent] = useState(
		window.sessionStorage.getItem('formSent') ? true : false
	);
	const [invoiceNumber, setInvoiceNumber] = useState('');
	const [email, setEmail] = useState('');
	const [cf, setCf] = useState('');

	const { id } = useParams();

	const urlClick = (url: string) => {
		agent.Tracking.track('Click link: ' + url);
	};

	const viewInvoiceButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		let documentData = new DocumentData();
		documentData.documentId = Number(id);
		const acceptJson = {
			Email: email,
			Cf: cf,
			CodiceContratto: invoiceNumber,
			TrattamentoDati: true,
		};

		documentData.json = JSON.stringify(acceptJson);
		agent.Tracking.track(
			`SMAT electronic invoice form save ${JSON.stringify(acceptJson)}`
		);
		agent.Document.saveDocumentData(documentData);
		window.sessionStorage.setItem('formSent', 'true');
		setLoading(false);
		setFormSent(true);
	};

	return (
		<div
			className='d-md-flex new-customer-bg-color align-items-center'
			style={{
				minHeight: '100vh',
			}}
		>
			<div className='d-flex d-md-none justify-content-end'>
				<img
					alt=''
					className=''
					style={{
						maxWidth: '100%',
						paddingTop: '3%',
						width: '21rem',
					}}
					src='/images/smat/invoice/faucetCloudsLong.svg'
				/>
			</div>
			<div className='card mx-auto white-card shadow-lg w-75 h-75 new-customer-card'>
				<div className='row h-100'>
					<div
						className='col-md-4 new-customer-faucet-gradient d-md-flex d-none'
						style={{
							borderTopLeftRadius: '2rem',
							borderBottomLeftRadius: '2rem',
							padding: 0,
						}}
					>
						<img
							alt=''
							className=''
							style={{ maxWidth: '100%', paddingTop: '25%' }}
							src='/images/smat/invoice/faucetClouds.svg'
						/>
					</div>
					<div className='col-md-8 px-5 py-3'>
						{/* <div className='d-flex justify-content-between flex-column flex-md-row'>
							<img
								alt=''
								style={{ maxHeight: '5rem' }}
								src='/images/smat/invoice/logo.svg'
							/>
							<div className='mt-4 h4 text-md-start text-center'>
								{json.DatiFornitura.NumeroContratto.Etichetta}{' '}
								{json.DatiFornitura.NumeroContratto.Valore}
							</div>
						</div>
						<div className='display-3 fw-bold mt-5 text-center'>
							<div>
								{' '}
								{json.Riepilogo.Benvenuto.Etichetta}{' '}
								{json.Riepilogo.Benvenuto.Valore}
							</div>
						</div> */}
						{formSent ? (
							<div className='h5 mt-4 mb-5 small-text'>
								<strong>
									Grazie per aver scelto la bolletta interattiva SMAT.
								</strong>{' '}
								Una scelta responsabile e rispettosa dell’ambiente: consente
								infatti di non stampare più il documento e di evitarne la
								postalizzazione.
								<br />
								<br /> Con il nuovo servizio SMAT vuole contribuire a ridurre
								l’impatto ambientale e rendere più semplice la gestione delle
								spese domestiche, la bolletta interattiva offre infatti:
								<br />
								<br />
								<strong>accesso facile:</strong> in qualunque momento e da
								qualsiasi dispositivo. Con un click può visualizzare tutte le
								informazioni relative ai suoi consumi idrici e alle sue spese;
								<br />
								<br />
								<strong>dettagli chiari:</strong> può approfondire i dati
								esplorando i dati di consumo e le voci di spesa.
								<br />
								<br /> Il portale le consente di visualizzare in maniera
								semplice e chiara i dati principali della bolletta del servizio
								idrico e scaricare il pdf delle sue fatture.
								<br />
								<strong>
									Ha scelto di rinunciare alla fattura cartacea: l'ambiente
									ringrazia!
								</strong>
							</div>
						) : (
							<>
								<div className='h5 mt-2 small-text'>
									{/* <div>
										Se riceve ancora le fatture in formato cartaceo e non è
										ancora registrato al portale dell’Area Clienti può attivare
										gratuitamente il servizio compilando questo form e scegliere
										di ricevere le fatture elettroniche, tramite email.
										<br />
										<br />
										Attraverso il portale potrà visualizzare in maniera semplice
										e chiara i dati principali della bolletta e scaricare il pdf
										delle sue fatture.
										<br />
										<br />
									</div> */}
									<div className='h3 fw-bold d-md-block d-none'>
										Attivi subito il servizio per ricevere le fatture in formato
										elettronico.
									</div>
									<div className='h6 fw-bold d-md-none d-block'>
										Attivi subito il servizio per ricevere le fatture in formato
										elettronico.
									</div>
									<div>
										<br />
										La bolletta interattiva offre:
										<br />
										<strong>accesso facile:</strong> in qualunque momento e da
										qualsiasi dispositivo. Con un click può visualizzare tutte
										le informazioni relative ai suoi consumi idrici e alle sue
										spese;
										<br />
										<strong>dettagli chiari:</strong> può approfondire i dati
										esplorando i dati di consumo e le voci di spesa.
										<br />
										<br />
										L’attivazione è gratuita e, così facendo, ridurrà l’impatto
										ambientale e ottimizzerà lo spazio! Per ulteriori
										informazioni La invitiamo a contattare il Numero Verde 800
										010 010.
									</div>
								</div>
								<form onSubmit={(e) => viewInvoiceButtonClick(e)}>
									{
										/*isEmail &&*/ <div className='mt-3'>
											<input
												className='form-control small-text'
												type='text'
												required
												placeholder='Codice Fiscale / Partita Iva'
												value={cf}
												onChange={(e) => setCf(e.target.value)}
											/>
										</div>
									}
									{
										/*isEmail &&*/ <div className='mt-3'>
											<input
												className='form-control small-text'
												type='text'
												required
												placeholder='Codice Contratto'
												value={invoiceNumber}
												onChange={(e) => setInvoiceNumber(e.target.value)}
											/>
										</div>
									}
									{
										/*isEmail &&*/ <div className='mt-3'>
											<input
												className='form-control small-text'
												type='email'
												required
												placeholder='Email'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
									}
									<div className='mt-2'>
										<div className='form-check'>
											<input
												type='checkbox'
												className='form-check-input'
												style={{ border: 'solid 1px' }}
												id='privacy-check'
												checked={privacy}
												onChange={() => setPrivacy((old) => !old)}
											/>
											<label
												className='form-check-label small-text'
												htmlFor='privacy-check'
											>
												Acconsento al trattamento dei dati personali.
											</label>
										</div>
									</div>
									<div className='text-center mt-md-5 mt-3'>
										<button
											className='btn main-button'
											disabled={!privacy || !email}
											type='submit'
										>
											{loading ? (
												<Spinner
													as='span'
													animation='border'
													size='sm'
													role='status'
													aria-hidden='true'
												/>
											) : (
												//  !isEmail ? (
												// 	<span className='h4'>ACCETTO L'INVIO</span>
												// ) :
												<span className='h6'>ACCETTO</span>
											)}
										</button>
									</div>
								</form>
								<div className='mt-3 text-center'>
									<span className='me-md-3 me-1'>
										<a
											href={Static.smatYoutube}
											onClick={() => urlClick(Static.smatYoutube)}
											target='_blank'
											rel='noreferrer'
											className='btn'
										>
											<i className='fa-brands fa-xl fa-youtube'></i>
										</a>
									</span>
									<span className='me-md-3 me-1'>
										<a
											href={Static.smatFacebook}
											onClick={() => urlClick(Static.smatFacebook)}
											target='_blank'
											rel='noreferrer'
											className='btn'
										>
											<i className='fa-brands fa-xl fa-facebook'></i>
										</a>
									</span>
									<span className='me-md-3 me-1'>
										<a
											href={Static.smatTwitter}
											onClick={() => urlClick(Static.smatTwitter)}
											target='_blank'
											rel='noreferrer'
											className='btn'
										>
											<i className='fa-brands fa-xl fa-x-twitter'></i>
										</a>
									</span>
									<span className='me-0'>
										<a
											href={Static.smatInstagram}
											onClick={() => urlClick(Static.smatInstagram)}
											target='_blank'
											rel='noreferrer'
											className='btn'
										>
											<i className='fa-brands fa-xl fa-instagram'></i>
										</a>
									</span>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewCustomer;
