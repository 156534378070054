import { useRouteLoaderData } from 'react-router-dom';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import { Static } from '../../../../../static';
import agent from '../../../../../api/agent';
import { useStore } from '../../../../../context/store';

const Footer = () => {
	const json = useRouteLoaderData('smatIdricoJsonLoader') as BollettaJSON;

	const { modalStore } = useStore();

	const urlClick = (url: string) => {
		agent.Tracking.track('Click link: ' + url, url);
	};

	const isNewCustomer = json.NuovoCliente;

	const assistanceButtonClick = () => {
		agent.Tracking.track(Static.smatAssistanceButtonClick);
		modalStore.openModal(
			<div className='white-card fw-bold'>
				<div className='my-2'>
					<button className='btn' type='button' onClick={modalStore.closeModal}>
						<i className='fa-solid fa-arrow-left'></i>
					</button>
				</div>
				<div className='px-4 mb-4'>
					<h4 className='fw-bold'>Assistenza</h4>
					<br />
					{json.Contatti?.ProntoIntervento || ''}
					<br />
					<br />
					{json.Contatti?.AssistenzaUtenti}
				</div>
			</div>
		);
	};

	return (
		<div
			className={`main-bg-color text-white px-4 py-4 mt-md-5 ${
				isNewCustomer && 'darker footer'
			}`}
		>
			<div className='h3 px-1'>Contatti</div>
			<div className='row mt-3'>
				<div className='col-5 px-3 d-md-flex d-none'>
					{json.Contatti?.ProntoIntervento || ''}
				</div>
				<div className='col-md-5 offset-md-1'>
					<div>{json.Contatti.Societa?.Denominazione || ''}</div>
					<div>{json.Contatti.Societa?.SedeLegale || ''}</div>
					<div>{json.Contatti.Societa?.CapitaleSociale || ''}</div>
					<div>{json.Contatti.Societa?.PartitaIva || ''}</div>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-5 px-3 d-md-flex d-none'>
					{json.Contatti?.AssistenzaUtenti}
				</div>
				<div className='col-md-5 offset-md-1'>
					<div className='h3 text-white'>Seguici su</div>
					<div>
						<span className='me-3'>
							<a
								href={Static.smatYoutube}
								onClick={() => urlClick(Static.smatYoutube)}
								target='_blank'
								rel='noreferrer'
								className='btn footer-icon'
							>
								<i className='fa-brands fa-xl fa-youtube'></i>
							</a>
						</span>
						<span className='me-3'>
							<a
								href={Static.smatFacebook}
								onClick={() => urlClick(Static.smatFacebook)}
								target='_blank'
								rel='noreferrer'
								className='btn footer-icon'
							>
								<i className='fa-brands fa-xl fa-facebook'></i>
							</a>
						</span>
						<span className='me-3'>
							<a
								href={Static.smatTwitter}
								onClick={() => urlClick(Static.smatTwitter)}
								target='_blank'
								rel='noreferrer'
								className='btn footer-icon'
							>
								<i className='fa-brands fa-xl fa-x-twitter'></i>
							</a>
						</span>
						<span>
							<a
								href={Static.smatInstagram}
								onClick={() => urlClick(Static.smatInstagram)}
								target='_blank'
								rel='noreferrer'
								className='btn footer-icon'
							>
								<i className='fa-brands fa-xl fa-instagram'></i>
							</a>
						</span>
					</div>
				</div>
			</div>
			<div className='text-center mb-3 mt-4 d-block d-md-none'>
				<button
					className='btn main-button rounded-pill px-4 py-2 fw-bold'
					type='button'
					onClick={assistanceButtonClick}
				>
					ASSISTENZA
				</button>
			</div>
		</div>
	);
};

export default Footer;
