import { useEffect, useState } from 'react';

import agent from '../../../../../api/agent';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import CollapseButton from './CollapseButton';
import MyPopup from '../../../../../components/MyPopup';

type Props = {
	json: BollettaJSON;
	index: number;
	backToHomepage: () => void;
};

const Details = ({ json, backToHomepage, index }: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(false);

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) setIsMobile(true);
			else setIsMobile(false);
		};
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const urlClick = (url: string) => {
		agent.Tracking.track('Click link: ' + url, url);
	};
	return (
		<div className='card border-0 mt-md-4 white-card border-radius'>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					{/* <MobileHomepageButton backToHomepage={backToHomepage} /> */}
					<span className='h3 ms-5 ps-2 mt-1'>ALTRI DETTAGLI</span>
				</div>
				<CollapseButton
					section='Details'
					toggleFunction={setCollapseShowing}
					showing={collapseShowing}
				/>
			</div>
			<div
				className={`collapse ${!isMobile ? 'show' : collapseShowing && 'show'}`}
			>
				<div className='main-bg-gradient card-header'></div>
				<div className='px-4 mt-3 d-none d-md-block'>
					<div className='h3 my-2'>ALTRI DETTAGLI</div>
				</div>
				<div className='px-4 mt-3'>
					{json.DatiFornitura[index]?.AltriDettagli.UnitaDomestiche && (
						<div className='mt-3'>
							<span className='me-3'>
								{
									json.DatiFornitura[index]?.AltriDettagli.UnitaDomestiche
										.Etichetta
								}
							</span>
							<span className='fw-bold'>
								{
									json.DatiFornitura[index]?.AltriDettagli.UnitaDomestiche
										.Valore
								}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.Componenti && (
						<div className='mt-3 d-flex'>
							<span className='me-3 my-auto'>
								{json.DatiFornitura[index]?.AltriDettagli.Componenti.Etichetta}
							</span>
							<span className='fw-bold my-auto'>
								{json.DatiFornitura[index]?.AltriDettagli.Componenti.Valore}
							</span>
							{json.DatiFornitura[index]?.AltriDettagli.Componenti.Info && (
								<MyPopup
									description={
										json.DatiFornitura[index]?.AltriDettagli.Componenti.Info ||
										''
									}
									className='my-auto ms-2'
									buttonContent={
										<i className='fa-solid fa-circle-info fa-lg'></i>
									}
								/>
							)}
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.UnitaDomestiche && (
						<div className='mt-3'>
							<span className='me-3'>
								{
									json.DatiFornitura[index]?.AltriDettagli.UnitaNonDomestiche
										.Etichetta
								}
							</span>
							<span className='fw-bold'>
								{
									json.DatiFornitura[index]?.AltriDettagli.UnitaNonDomestiche
										.Valore
								}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.MinimoBollette && (
						<div className='mt-3'>
							<span className='me-3'>
								{
									json.DatiFornitura[index]?.AltriDettagli.MinimoBollette
										.Etichetta
								}
							</span>
							<span className='fw-bold'>
								{json.DatiFornitura[index]?.AltriDettagli.MinimoBollette.Valore}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.VolumeAnnuo && (
						<div className='mt-3'>
							<span className='me-3'>
								{json.DatiFornitura[index]?.AltriDettagli.VolumeAnnuo.Etichetta}
							</span>
							<span className='fw-bold'>
								{json.DatiFornitura[index]?.AltriDettagli.VolumeAnnuo.Valore}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.VolumePeriodo && (
						<div className='mt-3'>
							<span className='me-3'>
								{
									json.DatiFornitura[index]?.AltriDettagli.VolumePeriodo
										.Etichetta
								}
							</span>
							<span className='fw-bold'>
								{json.DatiFornitura[index]?.AltriDettagli.VolumePeriodo.Valore}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.AllaciamentoFognatura && (
						<div className='mt-3'>
							<span className='me-3'>
								{
									json.DatiFornitura[index]?.AltriDettagli.AllaciamentoFognatura
										.Etichetta
								}
							</span>
							<span className='fw-bold'>
								{
									json.DatiFornitura[index]?.AltriDettagli.AllaciamentoFognatura
										.Valore
								}
							</span>
						</div>
					)}
					{json.DatiFornitura[index]?.AltriDettagli.SmatCard && (
						<div className='mt-3 mb-5'>
							<span className='me-3'>
								<img
									alt=''
									style={{ maxHeight: '2.5rem' }}
									src='/images/smat/invoice/shieldCheck.png'
								/>
							</span>
							<span className='me-3'>
								{json.DatiFornitura[index]?.AltriDettagli.SmatCard.Etichetta}
							</span>
							<span className='fw-bold me-3'>
								{json.DatiFornitura[index]?.AltriDettagli.SmatCard.Valore}
							</span>
							<span>
								<a
									className='fw-bold'
									href='https://www.smatorino.it/smat-card/'
									onClick={() =>
										urlClick('https://www.smatorino.it/smat-card/')
									}
									target='_blank'
									rel='noreferrer'
								>
									Info attivazione
								</a>
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Details;
