import DownloadPdf from './DownloadPdf';
import { useEffect } from 'react';
import { useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { BollettaJSON } from '../models/jsonSmatIdrico';

const Header = () => {
	const json = useRouteLoaderData('smatIdricoJsonLoader') as BollettaJSON;
	const [darker, setDarker] = useOutletContext<any>();
	const isNewCustomer = json.NuovoCliente;

	useEffect(() => {
		if (isNewCustomer) setDarker(true);
		else setDarker(false);
	}, [isNewCustomer, setDarker]);

	return (
		<div
			className={`row px-md-4 py-3 sticky-top bg-white ${darker && 'darker'}`}
			style={{ margin: 0 }}
		>
			<div className='col-md-6 col-5'>
				<img
					alt=''
					className='responsive-image'
					src='/images/smat/invoice/logo.svg'
				/>
			</div>
			<div className='col-md-6 col-7'>
				<div className='d-flex justify-content-between'>
					<div>
						<span className='me-md-3'>
							<img
								className='responsive-image'
								alt=''
								src='/images/smat/invoice/smallFaucet.svg'
							/>
						</span>
						<span className='main-text-color fw-bold'>{json.TipoBolletta}</span>
					</div>
					<div className='d-md-flex d-none'>
						<DownloadPdf json={json} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
