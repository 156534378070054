import { useEffect, useState } from 'react';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import CollapseButton from './CollapseButton';
import MyPopup from '../../../../../components/MyPopup';

type Props = {
	json: BollettaJSON;
	backToHomepage: () => void;
};

const Amounts = ({ json, backToHomepage }: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) setIsMobile(true);
			else setIsMobile(false);
		};
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className='card border-0 mt-md-4 white-card border-radius'>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					{/* <MobileHomepageButton backToHomepage={backToHomepage} /> */}
					<span className='h3 ms-5 ps-2 mt-1'>RIEPILOGO IMPORTI</span>
				</div>
				<CollapseButton
					section='amounts'
					toggleFunction={setCollapseShowing}
					showing={collapseShowing}
				/>
			</div>
			<div
				className={`collapse ${!isMobile ? 'show' : collapseShowing && 'show'}`}
			>
				<div className='main-bg-gradient card-header'></div>
				<div className='px-4 mt-3'>
					<div className='h3 my-2 d-none d-md-block'>RIEPILOGO IMPORTI</div>
					<table className='table' id='amounts-table'>
						<thead>
							<tr>
								<th scope='col'></th>
								<th scope='col'>Importi (€)</th>
								<th scope='col'>IVA (%)</th>
							</tr>
						</thead>
						<tbody>
							{json.Importi.map((x, index) => (
								<tr key={index}>
									<td className='d-flex'>
										<span className='my-auto'>{x.Descrizione}</span>
										{x.Info && (
											<MyPopup
												description={x.Info}
												className='ms-2 my-auto'
												buttonContent={
													<i className='fa-solid fa-circle-info fa-lg'></i>
												}
											/>
										)}
									</td>
									<td className='fw-bold'>{x.Valore}</td>
									<td className='fw-bold'>
										{x.Iva === undefined ? '' : x.Iva}
									</td>
								</tr>
							))}
							{json.ImportoTotale && (
								<tr>
									<td className='h5 fw-bold'>{json.ImportoTotale.Etichetta}</td>
									<td className='main-text-color fw-bold h5'>
										{json.ImportoTotale.Valore}
									</td>
									<td></td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Amounts;
