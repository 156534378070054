import { toast } from 'react-toastify';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import { useStore } from '../../../../../context/store';
import Payment from './Payment';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';

type Props = { json: BollettaJSON; setCurrentView: (view: number) => void };

const MobileMenu = ({ json, setCurrentView }: Props) => {
	const { modalStore } = useStore();
	const downloadPdf = async () => {
		console.log(json.NomePdf);
		agent.Tracking.track('SMAT ' + Static.onDownloadInvoiceClick);
		toast.warning('Non ancora implementato');
		return;
		// TODO : Da implementare
		// agent.Document.getEncodedUrl(props.datiBolletta)
		// 	.then((url) => {
		// 		setLoading(false);
		// 		window.open(url, '_blank');
		// 	})
		// 	.catch(() => {
		// 		setLoading(false);
		// 	});
	};

	const emptyFunc = () => {};

	const paymentButtonClick = () => {
		agent.Tracking.track('SMAT mobile payment menu button click');
		modalStore.openModal(
			<div className='white-card'>
				<div className='my-2'>
					<button className='btn' type='button' onClick={modalStore.closeModal}>
						<i className='fa-solid fa-arrow-left'></i>
					</button>
				</div>
				<Payment json={json} backToHomepage={emptyFunc} />
			</div>
		);
	};

	return (
		<div className='d-flex justify-content-around main-bg-color py-3 px-5 border-radius'>
			<div className='text-center'>
				<button
					className='btn rounded-circle gray-text bg-white'
					onClick={downloadPdf}
				>
					<i className='fa-solid fa-download'></i>
				</button>
				<div className='gray-text small-text fw-bold mt-1 d-block'>
					DOWNLOAD
				</div>
			</div>
			<div className='text-center'>
				<button
					className='btn rounded-circle gray-text bg-white'
					onClick={() => setCurrentView(3)}
				>
					<i className='fa-solid fa-arrow-trend-up'></i>
				</button>
				<div className='gray-text small-text fw-bold mt-1 d-block'>CONSUMO</div>
			</div>
			<div className='text-center'>
				<button
					className='btn rounded-circle gray-text bg-white'
					onClick={() => setCurrentView(4)}
				>
					<i className='fa-solid fa-location-dot'></i>
				</button>
				<div className='gray-text small-text fw-bold mt-1 d-block'>
					SPORTELLO
				</div>
			</div>
			<div className='text-center'>
				<button
					className='btn rounded-circle gray-text bg-white'
					onClick={paymentButtonClick}
				>
					<i className='fa-solid fa-wallet'></i>
				</button>
				<div className='gray-text small-text fw-bold mt-1 d-block'>
					PAGAMENTO
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
