import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

import agent from '../../../../../api/agent';
import { toast } from 'react-toastify';
import { Static } from '../../../../../static';
import { BollettaJSON } from '../models/jsonSmatIdrico';

type Props = { json: BollettaJSON };

function DownloadPdf({ json }: Props) {
	const [loading, setLoading] = useState(false);

	const downloadPdf = async () => {
		agent.Tracking.track('SMAT ' + Static.onDownloadInvoiceClick);
		toast.warning('Non ancora implementato');
		setLoading(false);
		return;
		// TODO : Download Bolletta
		// agent.Document.getEncodedUrl(props.datiBolletta)
		// 	.then((url) => {
		// 		setLoading(false);
		// 		window.open(url, '_blank');
		// 	})
		// 	.catch(() => {
		// 		setLoading(false);
		// 	});
	};

	return (
		<button
			id='download-pdf'
			className='main-button btn my-auto px-5 py-3'
			onClick={downloadPdf}
		>
			{loading ? (
				<Spinner
					as='span'
					animation='border'
					size='sm'
					role='status'
					aria-hidden='true'
				/>
			) : (
				<>
					<i className='fa-solid fa-download me-2 fa-xl'></i>
					<span className='h5'> Scarica la bolletta</span>
				</>
			)}
		</button>
	);
}

export default DownloadPdf;
