import { useEffect } from 'react';
import { useRouteLoaderData } from 'react-router';
import { Chart } from 'chart.js';

import { Json } from '../models/json';
import ClientConsumptionFooter from './modal/ClientConsumptionFooter';

type Props = {};

const ClientConsumption = (props: Props) => {
	const json = useRouteLoaderData('demoJsonLoader') as Json;

	let barChart: Chart;

	let totalChartLabels: string[] = [];
	let totalChartF1: number[] = [];
	let totalChartF2: number[] = [];
	let totalChartF3: number[] = [];
	let totalChartF0: number[] = [];
	let totalChartPotenza: number[] = [];

	let screenWidth: number;

	let size = 16;
	let padding = 30;
	let barChartHeight = 700;

	useEffect(() => {
		if (json && json.GraficoEle && json.GraficoEle.length > 0) {
			json.GraficoEle.forEach((value) => {
				totalChartLabels.push(value.Mese);
				totalChartF0.push(+value.F0);
				totalChartF1.push(+value.F1);
				totalChartF2.push(+value.F2);
				totalChartF3.push(+value.F3);
				totalChartPotenza.push(+value.Potenza);
			});

			paintBarChart('barChart');
		}
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const onResize = () => {
		screenWidth = window.innerWidth;
		if (screenWidth <= 816) {
			size = 10;
			padding = 15;
			barChartHeight = 200;
		} else if (screenWidth <= 1100) {
			size = 12;
			padding = 15;
			barChartHeight = 400;
		} else {
			size = 16;
			padding = 30;
			barChartHeight = 500;
		}

		paintBarChart('barChart');
	};

	const paintBarChart = (idCanvas: string) => {
		if (barChart) barChart.destroy();

		var DatasetF0 = {
			label: 'F0',
			data: totalChartF0,
			yAxisID: 'F',
			backgroundColor: 'rgba(153, 203, 255, 0.8)',
			borderColor: 'rgba(153, 203, 255, 1)',
			borderWidth: 2,
		};

		var DatasetF1 = {
			label: 'F1',
			data: totalChartF1,
			yAxisID: 'F',
			backgroundColor: 'rgba(251, 99, 64, 0.8)',
			borderColor: 'rgba(251, 99, 64, 1)',
			borderWidth: 2,
		};

		var DatasetF2 = {
			label: 'F2',
			data: totalChartF2,
			yAxisID: 'F',
			backgroundColor: 'rgba(254, 185, 105, 0.8)',
			borderColor: 'rgba(254, 185, 105, 1)',
			borderWidth: 2,
		};

		var DatasetF3 = {
			label: 'F3',
			data: totalChartF3,
			backgroundColor: 'rgba(45, 206, 152, 0.8)',
			borderColor: 'rgba(45, 206, 152, 1)',
			yAxisID: 'F',
			borderWidth: 2,
		};

		barChart = new Chart(idCanvas, {
			type: 'bar',
			data: {
				labels: totalChartLabels,
				datasets: [
					DatasetF0,
					DatasetF1,
					DatasetF2,
					DatasetF3,
					{
						label: 'Potenza',
						data: totalChartPotenza,
						yAxisID: 'Potenza',
						borderColor: 'rgba(60, 60, 60, 1)',
						borderWidth: 2,
						type: 'line',
					},
				],
			},
			options: {
				scales: {
					x: {
						stacked: true,
						ticks: {
							font: {
								size: size,
								family: 'Montserrat',
							},
						},
					},
					F: {
						position: 'left',
						stacked: true,
						title: {
							display: true,
							text: 'kWh',
							font: {
								size: size,
								family: 'Montserrat',
								style: 'normal',
							},
							padding: padding,
						},
						ticks: {
							font: {
								size: size,
								family: 'Montserrat',
								style: 'normal',
							},
							padding: padding,
						},
					},
					Potenza: {
						position: 'right',
						title: {
							display: true,
							text: 'kW',
							padding: padding,
							font: {
								size: size,
								family: 'Montserrat',
								style: 'normal',
							},
						},
						ticks: {
							minRotation: 0,
							font: {
								size: size,
								family: 'Montserrat',
								style: 'normal',
							},
							padding: padding,
						},
					},
				},
				maintainAspectRatio: false,
				plugins: {
					datalabels: {
						display: false,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
							font: {
								family: 'Montserrat',
								size: size,
							},
							padding: padding,
						},
					},
					tooltip: {
						callbacks: {
							label: function (context: any) {
								var datasetLabel =
									context.chart.data.datasets[context.dataIndex].label || '';
								if (datasetLabel === 'Potenza') {
									return (
										datasetLabel +
										': ' +
										(+context.formattedValue)
											.toFixed(2)
											.toString()
											.replace('.', ',') +
										' kW'
									);
								}
								return (
									datasetLabel +
									': ' +
									(+context.formattedValue)
										.toFixed(2)
										.toString()
										.replace('.', ',') +
									' kWh'
								);
							},
						},
						bodyFont: {
							family: 'Montserrat',
							size: size,
						},
						titleFont: {
							family: 'Montserrat',
							size: size,
						},
						footerFont: {
							family: 'Montserrat',
							size: size,
						},
					},
				},
			},
		});
	};

	return (
		<div className='mt-4'>
			<div className='m-3 pb-5'>
				<div
					className='card p-3 border border-3 border-success p-md-5'
					hidden={!json.GraficoEle}
				>
					<h3 className='green'>Consumi mensili</h3>
					<h5 className='black'>
						Il grafico riporta i consumi effettuati negli ultimi mesi suddivisi
						per fasce.
					</h5>
					<div className='mt-5 w-100' style={{ height: barChartHeight + 'px' }}>
						<canvas id='barChart'></canvas>
					</div>
				</div>
			</div>
			<ClientConsumptionFooter />
		</div>
	);
};

export default ClientConsumption;
