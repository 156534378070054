import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
	description: string;
	buttonContent: JSX.Element;
	className?: string;
};

const MyPopup = ({ description, buttonContent, className }: Props) => {
	const renderTooltip = <Tooltip>{description}</Tooltip>;

	return (
		<OverlayTrigger
			placement='top'
			delay={{ show: 250, hide: 400 }}
			overlay={renderTooltip}
		>
			<button className={`btn ${className}`}>{buttonContent}</button>
		</OverlayTrigger>
	);
};

export default MyPopup;
