import React from 'react';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import { useStore } from '../../../../../context/store';

type Props = {
	json: BollettaJSON;
};

const Summary = ({ json }: Props) => {
	const { modalStore } = useStore();
	const mailToSmatInfo = () => {
		agent.Tracking.track(Static.smatMailToInfo, 'info@smatorino.it');
		window.open('mailto:info@smatorino.it', '_blank');
	};

	const viewUnpaidInvoicesButtonClick = () => {
		agent.Tracking.track(Static.smatAssistanceButtonClick);
		modalStore.openModal(
			<div className='white-card fw-bold'>
				<div className='my-2 d-flex'>
					<button className='btn' type='button' onClick={modalStore.closeModal}>
						<i className='fa-solid fa-arrow-left'></i>
					</button>
					<h4 className='fw-bold ms-2'>Bollette non saldate</h4>
				</div>
				<table className='table' id='amounts-table'>
					<thead>
						<tr>
							<th scope='col'>Numero bolletta</th>
							<th scope='col'>Data emissione</th>
							<th scope='col'>Importo (€)</th>
						</tr>
					</thead>
					<tbody>
						{json.Riepilogo.BolletteNonSaldate?.length &&
							json.Riepilogo.BolletteNonSaldate.map((x) => (
								<tr>
									<td>{x.NumeroBolletta || ''}</td>
									<td>{x.DataEmissione || ''}</td>
									<td>{x.Importo || ''}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<div className='card main-bg-gradient border-0 py-3 mt-lg-4 border-radius'>
			<div className='row text-white mw-100'>
				<div className='col-lg-8 ps-5 mt-4 d-flex justify-content-between'>
					<div>
						{json.Riepilogo.DenominazioneCliente && (
							<h1>{json.Riepilogo.DenominazioneCliente}</h1>
						)}
						{json.Riepilogo.NumeroFattura && (
							<div className='mt-4'>
								<span className='me-3'>
									{json.Riepilogo.NumeroFattura.Etichetta}
								</span>
								<span className='fw-bold d-lg-inline d-block'>
									{json.Riepilogo.NumeroFattura.Valore}
								</span>
							</div>
						)}
						{json.Riepilogo.PeriodoFatturazione && (
							<div className='mt-2'>
								<span className='me-3'>
									{json.Riepilogo.PeriodoFatturazione.Etichetta}
								</span>
								<span className='fw-bold d-lg-inline d-block'>
									{json.Riepilogo.PeriodoFatturazione.Valore}
								</span>
							</div>
						)}
						{json.Riepilogo.DataEmissione && (
							<div className='mt-2'>
								<span className='me-3'>
									{json.Riepilogo.DataEmissione.Etichetta}
								</span>
								<span className='fw-bold d-lg-inline d-block'>
									{json.Riepilogo.DataEmissione.Valore}
								</span>
							</div>
						)}
						{json.Riepilogo.ScadenzaBolletta && (
							<div className='mt-2'>
								<span className='me-3'>
									{json.Riepilogo.ScadenzaBolletta.Etichetta}
								</span>
								<span className='fw-bold d-lg-inline d-block'>
									{json.Riepilogo.ScadenzaBolletta.Valore}
								</span>
							</div>
						)}
						{json.Riepilogo.Totale && (
							<div className='mt-3'>
								<span className='d-lg-inline d-block'>
									{json.Riepilogo.Totale.Etichetta}
								</span>
								<h1 className='me-3 fw-bold'>
									€ {json.Riepilogo.Totale.Valore.toFixed(2)}
								</h1>
							</div>
						)}
						{json.Riepilogo.Regolare ? (
							<div>Le precedenti bollette risultato pagate. Grazie</div>
						) : (
							<div>
								Si segnala che alcune delle tue bollette precedenti risultano
								ancora non saldate
								<button
									className='ms-2 btn main-button px-4 py-2 mt-2 mt-lg-0'
									onClick={viewUnpaidInvoicesButtonClick}
								>
									Visualizza
								</button>
							</div>
						)}
						{json.Pagamenti.BollettaStorno && (
							<div>
								<p>
									Il presente documento di storno è relativo alla fattura numero{' '}
									<span className='fw-bold'>{json.Pagamenti.DocStorno}</span>{' '}
									emessa il{' '}
									<span className='fw-bold'>{json.Pagamenti.DataStorno}</span>
								</p>
							</div>
						)}
						{!json.Pagamenti.BollettaStorno &&
							json.Riepilogo.Totale.Valore < 0 && (
								<div>
									<p>
										Si invita a comunicare gli estremi bancari per l'accredito
										con bonifico dell'importo inoltrando comunicazione scritta
										alla mail{' '}
										<span className='link-style' onClick={mailToSmatInfo}>
											info@smatorino.it
										</span>{' '}
										e specificando sempre il codice ncontratto.
									</p>
									<p>
										Qualora sussistano bollette precedenti non pagate si invita
										a provvedere al relativo saldo al netto dell'importo della
										presente.
									</p>
								</div>
							)}
						{!json.Pagamenti.BollettaStorno &&
							json.Pagamenti.DomiciliatoSEPA &&
							json.Riepilogo.Totale.Valore > 0 && (
								<div>
									<p>
										L'importo della presente fattura è addebitato, s.b.f., sul
										Suo conto corrente.
									</p>
								</div>
							)}
					</div>
					<img
						alt=''
						className='faucet-clouds d-lg-none d-block'
						src='/images/smat/invoice/faucetClouds.svg'
					/>
				</div>
				{json.Riepilogo.BollettaRecapito && (
					<div className='col-lg-4 mt-lg-5 mt-1 px-5 ps-lg-0'>
						<div className='d-block d-lg-flex justify-content-between'>
							<div>
								<h3 className='fw-bold d-none'>
									{/**remove d-none, temporily hidden because they asked for it */}
									{json.Riepilogo.BollettaRecapito.Descrizione}
								</h3>
								{/* <div className='d-flex d-lg-block justify-content-between'>
									{json.Riepilogo.BollettaRecapito.NominativoRecapito && (
										<div className='mt-4'>
											<div>{"Associata all'utente"}</div>
											<div className='fw-bold'>
												{json.Riepilogo.BollettaRecapito.NominativoRecapito}
											</div>
										</div>
									)}
									{json.Riepilogo.BollettaRecapito.IndirizzoRecapito && (
										<div className='mt-4'>
											<div>{"E all'indirizzo"}</div>
											<div className='fw-bold'>
												{json.Riepilogo.BollettaRecapito.IndirizzoRecapito}
											</div>
										</div>
									)}
								</div> */}
							</div>
							<img
								alt=''
								className='faucet-clouds d-lg-block d-none'
								src='/images/smat/invoice/faucetClouds.svg'
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Summary;
