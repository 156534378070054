export class Static {
	public static invoiceKey = 'numeroCliente';

	/* Url START */
	public static urlGoogleMaps = 'https://www.google.com/maps/place/';

	/* IREN */
	public static urlIren = 'https://www.irenlucegas.it/';
	public static urlIrenCustomerArea = 'https://clienti.irenlucegas.it/login';
	public static urlIrenYouTube =
		'https://www.youtube.com/channel/UCRIlWe661LLYw9J0H0iZX0Q';
	public static urlIrenFacebook =
		'https://www.facebook.com/irenlucegas/?ref=br_rs';
	public static urlIrenInstagram =
		'https://www.instagram.com/irenlucegaseservizi/?hl=it';
	public static urlIrenTwitter = 'https://twitter.com/irenlucegas';
	public static urlIrenLinkedin =
		'https://www.linkedin.com/company/iren-luce-gas/';
	public static urlIrenClientiRichieste =
		'https://www.irenlucegas.it/clienti/richieste';
	public static urlIrenAppStore =
		'https://apps.apple.com/us/app/irenyou/id1497897096';
	public static urlIrenGooglePlay =
		'https://play.google.com/store/apps/details?id=it.irenyou.app';
	public static urlIrenContatti =
		'https://www.irenlucegas.it/clienti/contatti-e-sportelli';
	public static urlIrenHowToReadYourBill =
		'https://www.irenlucegas.it/clienti/bolletta/guida-alla-lettura-della-bolletta';
	public static urlIrenProcessingPersonalData =
		'https://www.irenlucegas.it/documents/66424/499644/IME_Informativa+contrattuale+clienti_20191119.pdf/395e22be-3129-4364-ba01-be08d9449eb5';

	/* SEV */
	public static urlSevProcessingPersonalData =
		'https://www.seviren.it/privacy-cookie-policy/';
	public static urlSevHowToReadYourBill =
		'https://www.seviren.it/guida-alla-bolletta/';
	public static urlSevContatti = 'https://www.seviren.it/dove-siamo/';
	public static urlSevClientRequest = 'https://www.seviren.it/faq/';

	public static urlSevFacebook = 'https://www.facebook.com/SevGruppoIren/';
	public static urlSevLinkedin =
		'https://it.linkedin.com/company/sevgruppoiren';

	/* DEMO */
	public static urlDemoCustomerArea = 'https://www.prtgroup.eu/it/';
	public static urlDemoPostaPronta = 'https://www.postapronta.eu/';
	public static urlDemoVirtualAssistant =
		'https://virtualassistant.alghoncloud.com/dhi/?bot=7a437f203c4b153a70e90b90995ab03c';
	public static urlDemoLinkedin = 'https://www.linkedin.com/company/prtspa/';
	public static urlDemoYouTube =
		'https://www.youtube.com/channel/UCYuGQ3y662Pr-Pc6LctyO9A';
	public static urlDemoProcessingPersonalData =
		'https://www.iubenda.com/privacy-policy/19873978';

	public static urlDemoPrivacy =
		'https://www.iubenda.com/privacy-policy/54061022/cookie-policy';

	/* VERITAS */
	public static urlVeritasSOL = 'https://serviziweb.gruppoveritas.it/';
	public static urlVeritasPrivacy = 'https://www.gruppoveritas.it/privacy';

	/* SMAT */
	public static urlSmatOnlineDesk =
		'https://sportello.smatorino.it/RetiVendita';

	/* Url END */

	/* Alertify Description START */
	public static youAreNotAuthenticated = 'Non sei autenticato';
	public static errorInRetrievingData = 'Errore nel recupero dei dati';
	public static thanks = 'La ringraziamo per la sua disponibilità';
	public static thanksForUpdatingYourRegistry =
		'La ringraziamo per aver aggiornato la sua anagrafica';
	public static anUnexpectedErrorHasOccurred =
		'Si è verificato un errore imprevisto!';
	public static theDocumentIsNoLongerAvailable =
		'Il documento non è più disponibile!';
	public static incorrectAuthenticationData =
		'Informazioni di autenticazione errate!';
	public static unsubscribeDialog =
		'In questo modo non riceverai più la tua bolletta in formato elettronico';
	public static unsubscribeFailed =
		"Ci dispiace, ma non è stato possibile completare l'unsubscribe";
	public static unsubscribeCompleted =
		"D'accordo, non riceverai più la tua bolletta in formato elettronico";
	public static saveFailed =
		'Ci dispiace, si è verificato un errore nel salvataggio dei dati';
	/* Alertify Description END */

	/* Tracking Descriptions START */
	public static fromClientDetailComponent = 'dal Component Dettaglio Cliente';
	public static fromHeaderComponent = 'dal Component Header';
	public static fromLoginComponent = 'dal Component di Login';
	public static fromMainComponent = 'dal Component Home';
	public static fromMenuComponent = 'dal Component Menu';
	public static fromMainFooterComponent = 'dal Footer Component della Home';
	public static fromFooterComponent = 'dal Footer Component';
	public static fromModalAppComponent = 'dalla Modale App';
	public static fromModalSelfReadingComponent = 'dalla Modale Autolettura';
	public static fromModalMyRequestsComponent = 'dalla Modale Le mie richieste';
	public static fromModalVideo = 'dalla Modale del Video';
	public static fromModalExpenseSummaryComponent =
		'dalla Modale Sommario Spese';
	public static fromClientConsumptionComponent =
		'dal Component Consumi Cliente';
	public static fromCollectionCenterComponent =
		'dal Component Centro di Raccolta';
	public static fromDocumentDetailComponent =
		'dal Component Dettaglio Documento';
	public static fromDocumentComponent = 'dal Component Documento Singolo';
	public static fromInvoiceComponent = 'dal Component Fattura';
	public static fromDocumentSummaryComponent = 'dal Sommario del Documento';
	public static fromMeterDetailComponent =
		'dal Component Info Lettura e Contatore';
	public static fromReferenceDeskComponent = 'dal Component Sportello Vicino';
	public static fromPaymentComponent = 'dal Component Pagamento';
	public static fromContractDetailComponent =
		'dal Component Dettaglio Contratto';
	public static fromEmptyingsComponent = 'dal Component Dettaglio Svuotamenti';

	public static selfReading = 'Autolettura';
	public static nonTiIncartare = 'Non ti incartare';
	public static termsOfPayment = 'Modalità di Pagamento';
	public static loginType = 'Tipo di Login';

	public static toggleClientDetail = 'Toggle Dettaglio Cliente';
	public static toggleAmountsSummary = 'Toggle Riepilogo Importi';
	public static toggleDetail = 'Toggle Dettaglio';
	public static goBack = 'Torna Indietro';
	public static toggleCancel = 'Torna alla Home';
	public static openModalVideo = 'Apri la Modale Video';
	public static openModalApp = 'Apri la Modale App';
	public static openModalExpenseSummary = 'Apri la Modale Sommario Spese';
	public static openModalSelfReading = 'Apri la Modale Autolettura';
	public static openModalMyRequests = 'Apri la Modale Le mie richieste';
	public static closeModalExpenseSummary = 'Chiudi la Modale Sommario Spese';
	public static closeModalApp = 'Chiudi la Modale App';
	public static closeModalSelfReading = 'Chiudi la Modale Autolettura';
	public static closeModalMyRequests = 'Chiudi la Modale Le mie richieste';
	public static closeModalVideo = 'Chiudi la Modale del Video';
	public static submitModalSelfReading = 'Invia la Modale Autolettura';
	public static submitModalMyRequests = 'Invia la Modale Le mie richieste';
	public static toggleClientConsumptionDetail =
		'Toggle Dettaglio Consumi Cliente';

	public static onMyRequestClick = 'Vai a Le mie Richieste';
	public static onSOLClick = 'Vai al SOL';
	public static onRememberMe = "Ricordami l'autolettura con un calendario";
	public static onShowDetails = 'Vai ad Altri Dettagli';
	public static onGoToDocument = 'Vai al Documento';
	public static onViewOnGoogleMapsClick = 'Vedi su Google Maps';
	public static onUnsubscribeConfirm = "Conferma per Annullare l'Iscrizione";
	public static onContactClick = 'Vai ai Contatti';
	public static onHowToReadYourBillClick = 'Come leggere la tua bolletta';
	public static onDownloadInvoiceClick = 'Scarica la bolletta';
	public static onProcessingPersonalDataClick = 'Trattamento Dati Personali';
	public static onGooglePlayClick = 'Googe Play Click';
	public static onAppStoreClick = 'App Store Click';
	public static onFacebookClick = 'Facebook Link';
	public static onLinkedinClick = 'Linkedin Link';
	public static onTwitterClick = 'Twitter Link';
	public static onInstagramClick = 'Instagram Link';
	public static onYoutubeClick = 'YouTube Link';
	/* Tracking Descriptions END */

	//PSA
	public static psaSoldStatusKey = 1;
	public static psaSoldStatus = 'Venduto';
	public static psaPrivacy = `INFORMATIVA PRIVACY
	<br>FINALITÀ E MODALITÀ DEL TRATTAMENTO DEI DATI PERSONALI - I dati
	personali del nuovo intestatario forniti (“Dati”) saranno trattati da
	Stellantis Euope S.p.A. (“Società”) per adempiere ad obblighi di legge
	della casa costruttrice in tema di sicurezza e conformità ad obblighi
	normativi. Il trattamento dei Dati per la finalità di cui sopra potrà
	avvenire con modalità cartacee, automatizzate o elettroniche.
	<br>CONSEGUENZE DELL’EVENTUALE MANCATO CONFERIMENTO DEI DATI -
	 Il conferimento di tali Dati è necessario alla Società per adempiere alla
	finalità di cui sopra.
	<br>ALTRI SOGGETTI CHE POSSONO TRATTARE I DATI - 
	 I Dati potranno essere trattati da soggetti operanti per conto della
	Società ed in forza di specifici vincoli contrattuali, in Paesi membri
	UE o extra UE. I Dati potranno essere comunicati a terzi per adempiere
	ad obblighi di legge, per rispettare ordini provenienti da pubbliche
	autorità o per esercitare un diritto della Società in sede
	giudiziaria.
	<br>TRASFERIMENTO DEI DATI AL DI FUORI DELLLO SPAZIO ECONOMICO EUROPEO (SEE) -
	 Nell’ambito dei rapporti contrattuali della Società i
	Dati potranno essere trasferiti al di fuori del territorio dello
	Spazio Economico Europeo (SEE), anche mediante l’inserimento degli
	stessi in database gestiti da società terze, operanti per conto della
	Società. La gestione dei database ed il trattamento dei Dati sono
	vincolati alla finalità per cui gli stessi sono stati raccolti ed
	avvengono nel massimo rispetto della legge applicabile sulla
	protezione dei dati personali. Tutte le volte in cui i Dati dovessero
	essere oggetto di trasferimento al di fuori del SEE, la Società
	adotterà ogni misura contrattuale idonea e necessaria a garantire un
	adeguato livello di protezione dei Dati, inclusi - tra gli altri - gli
	accordi basati sulle clausole contrattuali standard per il
	trasferimento dei dati al di fuori del SEE, approvate dalla
	Commissione Europea.
	<br>TITOLARE DEL TRATTAMENTO E DATA PROTECTION OFFICER -
	 Titolare del trattamento dei Dati è Stellantis Euope S.p.A., con sede legale in
	Corso G. Agnelli 200, 10135 Torino, Italia. L’interessato può
	contattare il team del Data Protection Officer all’indirizzo e-mail
	dpo@fcagroup.com.
	<br>CONSERVAZIONE DEI DATI -
	 I Dati trattati saranno conservati dalla
	Società per il periodo ritenuto strettamente necessario a conseguire
	la finalità di cui sopra. 
	<br>I DIRITTI DELL’INTERESSATO -
	 All’interessato sono riconosciuti i seguenti diritti, fatti salvi gli obblighi di
	legge a cui la Società è sottoposta in qualità di costruttore: 1.
	diritto di accesso, ossia il diritto di ottenere dalla Società la
	conferma che sia o meno in corso il trattamento dei Dati e, in tal
	caso, di ottenerne l’accesso; 2. diritto di rettifica e cancellazione,
	ossia il diritto di ottenere la rettifica di Dati inesatti e/o
	l’integrazione di Dati incompleti o la cancellazione dei Dati per
	motivi legittimi; 3. diritto alla limitazione del trattamento, ossia
	il diritto a richiedere la sospensione del trattamento qualora
	sussistano motivi legittimi; 4. diritto alla portabilità dei dati,
	ossia il diritto di ricevere in un formato strutturato, di uso comune
	e leggibile i Dati, nonché il diritto di trasmettere i Dati ad un
	altro titolare del trattamento; 5. diritto di opposizione, ossia il
	diritto opporsi al trattamento dei Dati qualora sussistano motivi
	legittimi, ivi compresi i trattamenti di Dati per finalità di
	marketing e profilazione, se previsti; 6. diritto di rivolgersi
	all’autorità per la protezione dei dati competente in caso di
	trattamento illecito dei Dati. L’interessato potrà esercitare i
	diritti sopra elencati scrivendo a Stellantis Euope S.p.A., in Corso
	G. Agnelli 200, 10135 Torino, Italia o all’indirizzo e-mail:
	privacy@fcagroup.com.`;
	//PSA END

	//SMAT

	public static smatYoutube =
		'https://www.youtube.com/channel/UCHCfIHiDRNqVIQHeYkBaQ5w';
	public static smatInstagram =
		'https://www.facebook.com/profile.php?id=190567415086247&_rdr';
	public static smatFacebook = 'https://www.instagram.com/smat_gruppo/?hl=it';
	public static smatTwitter = 'https://twitter.com/SmatGruppo';
	public static smatExternalLinkClick =
		'SMAT pagopa pay external link button click';
	public static smatPagoPaDatiAvviso =
		'https://checkout.pagopa.it/inserisci-dati-avviso';
	public static smatOnlineDeskExternalLinkClick =
		'SMAT Online Desk external link button click';
	public static smatAttachmentExternalLinkClick =
		'SMAT Pagamenti.Allegato external link button click';
	public static smatMailToContracts = 'SMAT MailTo contratti@smatorino.it';
	public static smatMailToInfo = 'SMAT MailTo info@smatorino.it';
	public static smatChangeUtilityButtonClick =
		'SMAT utility button click, selected utility:';
	public static smatDemoAcceptButtonClick =
		'SMAT demo accept invoice button click';
	public static smatAssistanceButtonClick = 'SMAT assistance button click';
	public static smatViewUnpaidInvoicesButtonClick =
		'SMAT view unpaid invoices button click';
	public static smatHomepageButtonClick = 'SMAT back to homepage button click';
	public static smatMainCounterAddress = 'Torino, corso XI Febbraio 22';
	public static smatCopyButtonClick = 'SMAT payment section copy button click ';
	//SMAT END
}
