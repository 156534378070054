import { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';

import { BollettaJSON } from '../models/jsonSmatIdrico';
import CollapseButton from './CollapseButton';

type Props = {
	index: number;
	json: BollettaJSON;
	backToHomepage: () => void;
};

const ConsumptionReadings = ({ json, backToHomepage, index }: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) setIsMobile(true);
			else setIsMobile(false);
		};
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const barArray =
		json.DatiFornitura[index]?.MediaConsumi.length > 15
			? json.DatiFornitura[index]?.MediaConsumi.slice(
					json.DatiFornitura[index]?.MediaConsumi.length - 15,
					json.DatiFornitura[index]?.MediaConsumi.length - 1
			  )
			: json.DatiFornitura[index]?.MediaConsumi;

	const lineArray =
		json.DatiFornitura[index]?.TotaleConsumi.length > 15
			? json.DatiFornitura[index]?.TotaleConsumi.slice(
					json.DatiFornitura[index]?.TotaleConsumi.length - 15,
					json.DatiFornitura[index]?.TotaleConsumi.length - 1
			  )
			: json.DatiFornitura[index]?.TotaleConsumi;

	const colorsBar = barArray.map(() => 'rgba(51, 133, 255, 1)');
	const colorsLine = lineArray.map(() => 'rgba(51, 133, 255, 1)');

	const maxLineValue = Math.max(...lineArray.map((x) => x.Valore));

	const barChartData: ChartData<'bar', number[], string> = {
		labels: barArray.map((x) => x.Inizio + '-' + x.Fine),
		datasets: [
			{
				label: 'Consumi medi giornalieri (mc/giorno)',
				data: barArray.map((x) => x.Media),
				backgroundColor: colorsBar,
				borderColor: colorsBar,
				borderWidth: 1,
				borderRadius: 20,
				maxBarThickness: 75,
			},
		],
	};

	const lineChartData: ChartData<'line', number[], string> = {
		labels: lineArray.map((x) => x.Data),
		datasets: [
			{
				label: "Consumi totali dall'ultima lettura (mc)",
				data: lineArray.map((x) => x.Valore),
				backgroundColor: colorsLine,
				borderColor: colorsLine,
				borderWidth: 1,
				pointStyle: 'circle',
				pointRadius: 10,
				pointHoverRadius: 15,
			},
		],
	};

	return (
		<div className='card border-0 mt-md-4 white-to-blue-card border-radius'>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					{/* <MobileHomepageButton backToHomepage={backToHomepage} color='white' /> */}
					<span className='h3 ms-5 ps-2 mt-1'>CONSUMI E LETTURE</span>
				</div>
				<CollapseButton
					section='Consumption readings'
					toggleFunction={setCollapseShowing}
					color='white'
					showing={collapseShowing}
				/>
			</div>
			<div
				className={`collapse ${!isMobile ? 'show' : collapseShowing && 'show'}`}
			>
				<div className='main-bg-gradient card-header'></div>
				<div className='px-4 mt-3 d-none d-md-block'>
					<div className='h3 my-2'>CONSUMI E LETTURE</div>
				</div>
				<div className='px-4 mt-3 row'>
					<div className='col-md-6 px-md-5 px-1 mt-4 bg-white mb-2 rounded'>
						<div className='h4 my-5'></div>
						<Line
							data={lineChartData}
							options={{
								aspectRatio: 2,
								scales: {
									y: {
										min: 0,
										max: Math.round(maxLineValue * 2),
									},
								},
							}}
						/>
					</div>
					<div className='col-md-6 px-md-5 px-1 bg-white my-2 rounded mb-4 mb-mt-0'>
						<div className='h4 my-2 gray-text small-text'>
							Andamento dei suoi consumi medi giornalieri (in base a letture
							rilevate e/o autoletture in mc/giorno)
						</div>
						<Bar
							data={barChartData}
							options={{
								aspectRatio: 2,
								scales: {
									y: {
										title: {
											display: true,
											text: 'mc',
											font: {
												size: isMobile ? 15 : 30,
											},
										},
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsumptionReadings;
