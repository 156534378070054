import axios from 'axios';
import { DocumentData } from '../projects/iveco/recall-campaign/vehicle/models/document-data';
import { DatiBolletta } from '../projects/veritas/invoice/models/dati-bolletta';
import { requests, responseBody } from './agent';

const Document = {
	getDocument: (id: number, skipDocumentData?: boolean | undefined) =>
		requests.get<any>(
			'api/document/documentid/' +
				id +
				(skipDocumentData === undefined ? '' : '/' + skipDocumentData)
		),
	getEncodedUrl: (datiBolletta: DatiBolletta) => {
		let params = new URLSearchParams();
		params.append('bolletta', datiBolletta.bolletta);
		params.append('bp', datiBolletta.bp);
		params.append('endPeriod', datiBolletta.endPeriod);
		params.append('startPeriod', datiBolletta.startPeriod);
		return requests.get<string>(
			'api/decrypter/getencodedurl?' + params.toString()
		);
	},
	getAttachment: (documentId: number, attachmentName: string) =>
		axios
			.get(
				'api/attachment/documentid/' +
					documentId +
					'/filename/' +
					attachmentName +
					'/',
				{ responseType: 'blob' }
			)
			.then(responseBody),
	unsubscribe: (documentId: number) => {
		var customerString = localStorage.getItem('customer') || '';
		var customer = JSON.parse(customerString);

		return requests.post<boolean>('api/unsubscribe/save', {
			DocumentId: documentId,
			CustomersInfo: customer,
		});
	},
	saveDocumentData: (documentData: DocumentData) =>
		requests.post('api/document/postdocumentData', documentData),
};

export default Document;
