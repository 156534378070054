import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../projects/veritas/invoice/components/Header';
import Footer from '../projects/veritas/invoice/components/Footer';
import PrivateRoute from './PrivateRoute';
import Login from '../projects/veritas/invoice/components/Login';
import TariDocument from '../projects/veritas/invoice/tari/components/Document';
import TariInvoice from '../projects/veritas/invoice/tari/components/Invoice';
import TariDocumentList from '../projects/veritas/invoice/tari/components/DocumentList';
import IdricoDocument from '../projects/veritas/invoice/idrico/components/Document';
import IdricoInvoice from '../projects/veritas/invoice/idrico/components/Invoice';
import IdricoDocumentList from '../projects/veritas/invoice/idrico/components/DocumentList';
import { NotFound } from './NotFound';
import agent from '../api/agent';
import { Static } from '../static';
import { DocumentVeritas as DocumentVeritasTari } from '../projects/veritas/invoice/tari/models/documentVeritas';
import { DocumentVeritas as DocumentVeritasIdrico } from '../projects/veritas/invoice/idrico/models/documentVeritas';
import { Document as DocumentDemoIdrico } from '../projects/demo/invoice/idrico/models/document';

import { store } from '../context/store';
import Main from '../projects/iveco/recall-campaign/vehicle/components/Main';
import IvecoLogin from '../projects/iveco/recall-campaign/vehicle/components/Login';
import { Document } from '../projects/iveco/recall-campaign/vehicle/models/document';
import DemoLogin from '../projects/demo/invoice/luce/components/Login';
import DemoHeader from '../projects/demo/invoice/luce/components/Header';
import DemoIdricoHeader from '../projects/demo/invoice/idrico/components/Header';
import DemoIdricoFooter from '../projects/demo/invoice/idrico/components/Footer';
import DemoIdricoDocument from '../projects/demo/invoice/idrico/components/Document';
import DemoIdricoDocumentList from '../projects/demo/invoice/idrico/components/DocumentList';
import DemoIdricoInvoice from '../projects/demo/invoice/idrico/components/Invoice';
import DemoIdricoLogin from '../projects/demo/invoice/idrico/components/Login';

import ClientDetail from '../projects/demo/invoice/luce/components/ClientDetail';
import DemoMain from '../projects/demo/invoice/luce/components/Main';
import { Document as DemoDocument } from '../projects/demo/invoice/luce/models/document';
import ClientConsumption from '../projects/demo/invoice/luce/components/ClientConsumption';
import VeritasGone from '../projects/veritas/invoice/layout/Gone';
import VeritasNotFound from '../projects/veritas/invoice/layout/NotFound';
import UnhandledError from './UnhandledError';

import PsaLogin from '../projects/psa/vehicle/components/Login';
import PsaMain from '../projects/psa/vehicle/components/Main';
import { Document as PsaDocument } from '../projects/psa/vehicle/models/json';

import { Document as SmatIdricoDocumentModel } from '../projects/smat/invoice/idrico/models/jsonSmatIdrico';
import SmatLogin from '../projects/smat/invoice/components/Login';
import SmatIdricoHeader from '../projects/smat/invoice/idrico/components/Header';
import SmatIdricoFooter from '../projects/smat/invoice/idrico/components/Footer';
import SmatIdricoDocument from '../projects/smat/invoice/idrico/components/Document';
import SmatNewCustomer from '../projects/smat/invoice/idrico/components/NewCustomer';
import NewCustomer from '../projects/smat/invoice/idrico/components/NewCustomer';

const router = createBrowserRouter([
	{
		path: '',
		element: <Outlet></Outlet>,
		errorElement: <Navigate to='/error' />,
		children: [
			{ index: true, element: <NotFound /> },
			{
				path: 'smat',
				lazy: async () => {
					let SmatMainContainer = await import(
						'../projects/smat/invoice/components/SmatMainContainer'
					);
					return { Component: SmatMainContainer.default };
				},
				children: [
					{
						index: true,
						element: <Navigate to='/not-found' />,
					},
					{
						path: 'newCustomer/:id',
						// loader: smatIdricoJsonLoader,
						element: (
							<PrivateRoute>
								<NewCustomer />
							</PrivateRoute>
						),
					},
					{
						path: 'missingEmail/:id',
						loader: smatIdricoJsonLoader,
						element: (
							<PrivateRoute>
								<SmatNewCustomer />
							</PrivateRoute>
						),
					},
					{
						path: 'invoice',
						children: [
							{
								path: 'idrico/login/:id',
								element: <SmatLogin />,
							},
							{
								path: 'idrico',
								loader: smatIdricoJsonLoader,
								id: 'smatIdricoJsonLoader',
								element: (
									<>
										<SmatIdricoHeader />
										<Outlet />
										<SmatIdricoFooter />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<SmatIdricoDocument />
											</PrivateRoute>
										),
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'veritas',
				lazy: async () => {
					let VeritasMainContainer = await import(
						'../projects/veritas/invoice/components/VeritasMainContainer'
					);
					return { Component: VeritasMainContainer.default };
				},
				children: [
					{
						index: true,
						element: <Navigate to='/veritas/not-found' />,
					},
					{
						path: 'not-found',
						element: <VeritasNotFound />,
					},
					{
						path: 'invoice',
						children: [
							{
								index: true,
								element: <Navigate to='/veritas/not-found' />,
							},
							{
								path: 'gone',
								element: <VeritasGone />,
							},
							{
								path: 'tari/login/:id',
								element: <Login />,
							},
							{
								path: 'tari',
								loader: veritasTariJsonLoader,
								id: 'veritasTariJsonLoader',
								element: (
									<>
										<Header
											isTari
											loaderTari='veritasTariJsonLoader'
											loaderIdrico=''
										/>
										<Outlet />
										<Footer />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/veritas/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<TariDocument loader='veritasTariJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id',
										element: (
											<PrivateRoute>
												<TariDocumentList loader='veritasTariJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id/invoice/:key',
										element: (
											<PrivateRoute>
												<TariInvoice loader='veritasTariJsonLoader' />
											</PrivateRoute>
										),
									},
								],
							},
							{
								path: 'idrico/login/:id',
								element: <Login />,
							},
							{
								path: 'idrico',
								loader: veritasIdricoJsonLoader,
								id: 'veritasIdricoJsonLoader',
								element: (
									<>
										<Header
											isTari={false}
											loaderTari=''
											loaderIdrico='veritasIdricoJsonLoader'
										/>
										<Outlet />
										<Footer />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/veritas/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<IdricoDocument loader='veritasIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id',
										element: (
											<PrivateRoute>
												<IdricoDocumentList loader='veritasIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id/invoice/:key',
										element: (
											<PrivateRoute>
												<IdricoInvoice loader='veritasIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
								],
							},
						],
					},
				],
			},
			//rotte temp per retrocompatibilità
			{
				path: 'invoice',
				children: [
					{
						index: true,
						element: <Navigate to='/veritas/not-found' />,
					},
					{
						path: 'veritas',
						lazy: async () => {
							let VeritasMainContainer = await import(
								'../projects/veritas/invoice/components/VeritasMainContainer'
							);
							return { Component: VeritasMainContainer.default };
						},
						children: [
							{
								index: true,
								element: <Navigate to='/veritas/not-found' />,
							},
							{
								path: 'tari/login/:id',
								element: <Login />,
							},
							{
								path: 'tari',
								loader: veritasTariJsonLoader,
								id: 'veritasTariJsonLoaderTemp',
								element: (
									<>
										<Header
											isTari
											loaderIdrico=''
											loaderTari='veritasTariJsonLoaderTemp'
										/>
										<Outlet />
										<Footer />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/veritas/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<TariDocument loader='veritasTariJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id',
										element: (
											<PrivateRoute>
												<TariDocumentList loader='veritasTariJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id/invoice/:key',
										element: (
											<PrivateRoute>
												<TariInvoice loader='veritasTariJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
								],
							},
							{
								path: 'idrico/login/:id',
								element: <Login />,
							},
							{
								path: 'idrico',
								loader: veritasIdricoJsonLoader,
								id: 'veritasIdricoJsonLoaderTemp',
								element: (
									<>
										<Header
											isTari={false}
											loaderIdrico='veritasIdricoJsonLoaderTemp'
											loaderTari=''
										/>
										<Outlet />
										<Footer />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/veritas/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<IdricoDocument loader='veritasIdricoJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id',
										element: (
											<PrivateRoute>
												<IdricoDocumentList loader='veritasIdricoJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id/invoice/:key',
										element: (
											<PrivateRoute>
												<IdricoInvoice loader='veritasIdricoJsonLoaderTemp' />
											</PrivateRoute>
										),
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'iveco',
				lazy: async () => {
					let IvecoMainContainer = await import(
						'../projects/iveco/recall-campaign/vehicle/components/IvecoMainContainer'
					);
					return { Component: IvecoMainContainer.default };
				},
				children: [
					{
						index: true,
						element: <Navigate to='/not-found' />,
					},
					{
						path: 'recall-campaign',
						children: [
							{
								index: true,
								element: <Navigate to='/not-found' />,
							},
							{
								path: 'vehicle',
								children: [
									{
										index: true,
										element: <Navigate to='/not-found' />,
									},
									{
										path: 'document/:id',
										loader: ivecoJsonLoader,
										element: <Main />,
									},
									{
										path: 'login/:id',
										element: <IvecoLogin />,
									},
								],
							},
						],
					},
				],
			},
			//rotte temp per retrocompatibilita'
			{
				path: 'recall-campaign',
				children: [
					{
						index: true,
						element: <Navigate to='/not-found' />,
					},
					{
						path: 'iveco',
						lazy: async () => {
							let IvecoMainContainer = await import(
								'../projects/iveco/recall-campaign/vehicle/components/IvecoMainContainer'
							);
							return { Component: IvecoMainContainer.default };
						},
						children: [
							{
								index: true,
								element: <Navigate to='/not-found' />,
							},
							{
								path: 'vehicle',
								children: [
									{
										index: true,
										element: <Navigate to='/not-found' />,
									},
									{
										path: 'document/:id',
										loader: ivecoJsonLoader,
										element: <Main />,
									},
									{
										path: 'login/:id',
										element: <IvecoLogin />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'demo',
				lazy: async () => {
					let DemoMainContainer = await import(
						'../projects/demo/invoice/components/DemoMainContainer'
					);
					return { Component: DemoMainContainer.default };
				},
				children: [
					{
						index: true,
						element: <Navigate to='/not-found' />,
					},
					{
						path: 'invoice',
						children: [
							{
								index: true,
								element: <Navigate to='/not-found' />,
							},
							{
								path: 'luce',
								children: [
									{
										index: true,
										element: <Navigate to='/not-found' />,
									},
									{
										path: 'document/:documentId',
										id: 'demoJsonLoader',
										loader: demoJsonLoader,
										element: (
											<>
												<DemoHeader />
												<Outlet></Outlet>
											</>
										),
										children: [
											{
												path: 'client',
												element: <ClientDetail />,
											},
											{
												path: 'consumption',
												element: <ClientConsumption />,
											},
											{ path: '', element: <DemoMain /> },
										],
									},
									{
										path: 'login/:type/:id',
										element: <DemoLogin />,
									},
								],
							},
							{
								path: 'idrico/login/:id',
								element: <DemoIdricoLogin />,
							},
							{
								path: 'idrico',
								loader: demoIdricoJsonLoader,
								id: 'demoIdricoJsonLoader',
								element: (
									<>
										<DemoIdricoHeader />
										<Outlet />
										<DemoIdricoFooter />
									</>
								),
								children: [
									{
										index: true,
										element: <Navigate to='/not-found' />,
									},
									{
										path: 'document/:id',
										element: (
											<PrivateRoute>
												<DemoIdricoDocument loader='demoIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id',
										element: (
											<PrivateRoute>
												<DemoIdricoDocumentList loader='demoIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
									{
										path: 'document-list/:id/invoice/:key',
										element: (
											<PrivateRoute>
												<DemoIdricoInvoice loader='demoIdricoJsonLoader' />
											</PrivateRoute>
										),
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'psa',
				lazy: async () => {
					let psaMainContainer = await import(
						'../projects/psa/vehicle/components/PsaVehicleMainContainer'
					);
					return { Component: psaMainContainer.default };
				},
				children: [
					{
						index: true,
						element: <Navigate to='/not-found' />,
					},
					{
						path: 'vehicle',
						children: [
							{
								index: true,
								element: <Navigate to='/not-found' />,
							},
							{
								path: 'document/:id',
								loader: psaJsonLoader,
								element: <PsaMain />,
							},
							{
								path: 'login/:id',
								element: <PsaLogin />,
							},
						],
					},
				],
			},
			{ path: 'not-found', element: <NotFound /> },
			{ path: '*', element: <NotFound /> },
		],
	},
	{
		path: 'error',
		element: <UnhandledError />,
	},
]);

async function veritasTariJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();
	if (!params.id || !Number(params.id)) {
		store.loaderStore.setAppLoadingFalse();
		return null;
	}

	return agent.Document.getDocument(Number(params.id))
		.then((document: DocumentVeritasTari) => {
			if (!document) {
				store.loaderStore.setAppLoadingFalse();
				return null;
			}

			store.loaderStore.setAppLoadingFalse();
			return document.Json;
		})
		.catch(() => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

async function veritasIdricoJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();

	if (!params.id || !Number(params.id)) {
		store.loaderStore.setAppLoadingFalse();
		return null;
	}

	return agent.Document.getDocument(params.id)
		.then((document: DocumentVeritasIdrico) => {
			if (!document) {
				store.loaderStore.setAppLoadingFalse();
				return null;
			}
			store.loaderStore.setAppLoadingFalse();

			return document.Json;
		})
		.catch(() => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

async function ivecoJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();
	return agent.Document.getDocument(params.id)
		.then((document: Document) => {
			if (!document) return null;

			store.loaderStore.setAppLoadingFalse();
			return document.Json;
		})
		.catch((error) => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

async function demoJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();
	return agent.Document.getDocument(params.documentId)
		.then((document: DemoDocument) => {
			if (!document) return null;

			var jsonCustomer = [
				{
					key: Static.invoiceKey,
					value: document.Json.NumeroCliente.toString(),
				},
			];
			localStorage.setItem('customer', JSON.stringify(jsonCustomer));
			store.loaderStore.setAppLoadingFalse();

			return document.Json;
		})
		.catch((error) => {
			toast.error(Static.errorInRetrievingData);
			return null;
		});
}

async function demoIdricoJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();

	if (!params.id || !Number(params.id)) {
		store.loaderStore.setAppLoadingFalse();
		return null;
	}

	return agent.Document.getDocument(params.id)
		.then((document: DocumentDemoIdrico) => {
			if (!document) {
				store.loaderStore.setAppLoadingFalse();
				return null;
			}
			store.loaderStore.setAppLoadingFalse();
			return document.Json;
		})
		.catch(() => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

async function psaJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();
	return agent.Document.getDocument(params.id, false)
		.then((document: PsaDocument) => {
			if (!document) return null;

			store.loaderStore.setAppLoadingFalse();
			return document.Json;
		})
		.catch((error) => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

async function smatIdricoJsonLoader({ params }: any) {
	store.loaderStore.setAppLoadingTrue();

	if (!params.id || !Number(params.id)) {
		store.loaderStore.setAppLoadingFalse();
		return null;
	}

	return agent.Document.getDocument(params.id)
		.then((document: SmatIdricoDocumentModel) => {
			if (!document) {
				store.loaderStore.setAppLoadingFalse();
				return null;
			}
			store.loaderStore.setAppLoadingFalse();
			return document.Json;
		})
		.catch(() => {
			toast.error(Static.errorInRetrievingData);
			store.loaderStore.setAppLoadingFalse();
			return null;
		});
}

export default router;
