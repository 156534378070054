const downloadIcs = (
	description: string,
	startDate: Date,
	endDate: Date,
	id: string,
	summary: string,
	location: string
) => {
	let calendarData = [
		'BEGIN:VCALENDAR',
		'BEGIN:VEVENT',
		'UID:' + id,
		'DTSTAMP:' + formatDateToICSDate(new Date()),
		'DESCRIPTION:' + description,
		'DTSTART;VALUE=DATE:' + formatDateToICSDate(startDate),
		'DTEND;VALUE=DATE:' + formatDateToICSDate(endDate),
		'LOCATION:' + location,
		'SUMMARY:' + summary,
		'TRANSP:TRANSPARENT',
		'END:VEVENT',
		'END:VCALENDAR',
	]
		.join('\n')
		.replace(/^(\s*\r?\n){1,}/, '\n');

	const blob = new Blob([calendarData], { type: 'text/plain' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.download = 'rememberMeCalendarEvent.ics';
	link.href = url;
	link.click();

	// window.open('data:text/calendar;charset=utf8,' + calendarData);
};

const formatDateToICSDate = (date: Date) => {
	// Funzione di supporto per aggiungere zero iniziali se necessario
	function pad(numero: number) {
		return numero < 10 ? '0' + numero : numero;
	}

	const anno = date.getFullYear();
	const mese = pad(date.getMonth() + 1); // Mese è 0-11, quindi aggiungi 1
	const giorno = pad(date.getDate());
	const ore = pad(date.getHours());
	const minuti = pad(date.getMinutes());
	const secondi = pad(date.getSeconds());

	// Genera la stringa formattata
	const formattedDate = `${anno}${mese}${giorno}T${ore}${minuti}${secondi}Z`;

	return formattedDate;
};

const formatDateYearMonthDay = (date: Date) => {
	const dateString = date.toISOString().split('T')[0];
	return dateString;
};

function isValidDate(date: Date) {
	return date instanceof Date && !isNaN(date.getTime());
}

function getDateFromItalianFormatString(date: string) {
	var parts = date.split('/');
	var day = parseInt(parts[0], 10);
	var month = parseInt(parts[1], 10) - 1; // Sottrai 1 perché i mesi in JavaScript sono zero-based
	var year = parseInt(parts[2], 10);

	return new Date(year, month, day);
}

const formatToItalianDateString = (date: Date, separator: string) => {
	return (
		date.getDay() +
		separator +
		(date.getMonth() + 1) +
		separator +
		date.getFullYear()
	);
};

const utils = {
	downloadIcs,
	isValidDate,
	formatDateYearMonthDay,
	getDateFromItalianFormatString,
	formatToItalianDateString,
};

export default utils;
