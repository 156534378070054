import React from 'react';
import agent from '../../../../../api/agent';

type Props = {
	toggleFunction: React.Dispatch<React.SetStateAction<boolean>>;
	showing: boolean;
	section: string;
	color?: string;
};

const CollapseButton = (props: Props) => {
	const toggle = () => {
		agent.Tracking.track(
			`SMAT ${props.section} mobile collapse toggle button click`
		);
		props.toggleFunction((old) => !old);
	};
	return (
		<button onClick={toggle} className='btn' type='button'>
			<i
				className={`fa-solid fa-arrow-${
					props.showing ? 'up' : 'down'
				} fa-lg text-${props.color}`}
			></i>
		</button>
	);
};

export default CollapseButton;
