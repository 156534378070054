import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../../../context/store';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';

type Props = {
	documentId: number;
};

const NewCustomerButton = (props: Props) => {
	const navigate = useNavigate();
	const { userStore } = useStore();
	const newCustomerButtonClick = () => {
		agent.Tracking.track(
			Static.smatDemoAcceptButtonClick,
			`/smat/newCustomer/${props.documentId}`
		);
		navigate(`/smat/newCustomer/${props.documentId}`);
	};

	const shareButtonClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		agent.Tracking.track('SMAT share demo button click');
		if (navigator.share) {
			navigator
				.share({
					// Title that occurs over
					// web share dialog
					title: 'electronic invoice url',

					// URL to share
					url: userStore.loginUrl || 'https://www.prtspa.com/it/',
				})
				.then(() => {
					console.log('ok');
				})
				.catch((err) => {});
		} else {
			// Alerts user if API not available
			alert("Browser doesn't support this API !");
		}
	};

	return (
		<div className='white-card bg-white py-4 px-4 text-center fixed-bottom new-customer-button'>
			<span className='d-block h1 fw-bold'>Cosa aspetti?</span>
			<span className='h5 mt-2'>
				Clicca il pulsante in basso per ricevere la tua bolletta digitale
			</span>
			<br />
			<div className='mt-3'>
				<button
					className='btn btn-lg main-button rounded-pill py-2 fw-bold new-customer-fixed-width-button'
					type='button'
					onClick={newCustomerButtonClick}
				>
					ACCETTO L'INVIO
				</button>
				<button
					className='btn btn-lg main-button rounded-pill py-2 fw-bold new-customer-fixed-width-button ms-md-3 ms-0 mt-2 mt-md-0'
					type='button'
					onClick={(e) => shareButtonClick(e)}
				>
					CONDIVIDI
				</button>
			</div>
		</div>
	);
};

export default NewCustomerButton;
