import React from 'react';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import { toast } from 'react-toastify';

type Props = {
	textToCopy: string;
};
console.log('ok');

const CopyButton = (props: Props) => {
	const copyButtonClick = () => {
		agent.Tracking.track(Static.smatCopyButtonClick + props.textToCopy);
		navigator.clipboard.writeText(props.textToCopy);
		toast.success('Copiato!');
	};
	return (
		<button className='btn' onClick={copyButtonClick}>
			<i className='fa-solid fa-copy'></i>
		</button>
	);
};

export default CopyButton;
