import React from 'react';

type Props = {
	backToHomepage: () => void;
	color?: string;
};

const MobileHomepageButton = (props: Props) => {
	return (
		<button
			className='btn align-self-start d-inline-block d-md-none'
			onClick={props.backToHomepage}
		>
			<i className={`fa-solid fa-arrow-left fa-lg text-${props.color}`}></i>
		</button>
	);
};

export default MobileHomepageButton;
