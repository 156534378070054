import { useState } from 'react';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import CollapseButton from './CollapseButton';

type Props = {
	json: BollettaJSON;
	index: number;
	backToHomepage: () => void;
};

const Supply = ({ json, backToHomepage, index }: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(true);

	return (
		<div className='card border-0 mt-md-4 white-card border-radius'>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					{/* <MobileHomepageButton backToHomepage={backToHomepage} /> */}
					<span className='h3 ms-5 ps-2 mt-1'>DATI FORNITURA</span>
				</div>
				<CollapseButton
					section='Supply'
					toggleFunction={setCollapseShowing}
					showing={collapseShowing}
				/>
			</div>
			<div className={`collapse ${collapseShowing ? 'show' : ''}`}>
				<div>
					<div className='main-bg-gradient card-header'></div>
					<div className='px-4 mt-3'>
						<div className='h3 my-2 d-none d-md-block'>DATI FORNITURA</div>
						<div>
							<span className='fw-bold'>
								{json.Riepilogo?.DenominazioneCliente}
							</span>
						</div>
						{json.Riepilogo?.CodiceFiscale && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.Riepilogo?.CodiceFiscale.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.Riepilogo?.CodiceFiscale.Valore}
								</span>
							</div>
						)}
						{/* {json.DatiFornitura[index].CodiceUtente && (
						<div className='mt-3'>
							<span className='me-3'>
								{json.DatiFornitura[index].CodiceUtente.Etichetta}
							</span>
							<span className='fw-bold d-block d-md-inline'>
								{json.DatiFornitura[index].CodiceUtente.Valore}
							</span>
						</div>
					)} */}
						{json.DatiFornitura[index].NumeroContratto && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.DatiFornitura[index].NumeroContratto.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].NumeroContratto.Valore}
								</span>
							</div>
						)}
						{/* {json.DatiFornitura[index].Residenza && (
						<div className='mt-3'>
							<span className='me-3'>
								{json.DatiFornitura[index].Residenza.Etichetta}
							</span>
							<span className='fw-bold d-block d-md-inline'>
								{json.DatiFornitura[index].Residenza.Valore}
							</span>
						</div>
					)} */}
						{json.DatiFornitura[index].IndirizzoFornitura && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.DatiFornitura[index].IndirizzoFornitura.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].IndirizzoFornitura.Valore}
								</span>
							</div>
						)}
						{json.DatiFornitura[index].PDP && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.DatiFornitura[index].PDP.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].PDP.Valore}
								</span>
							</div>
						)}
						{json.DatiFornitura[index].DataAttivazione && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.DatiFornitura[index].DataAttivazione.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].DataAttivazione.Valore}
								</span>
							</div>
						)}
						{json.DatiFornitura[index].TipologiaUtenza && (
							<div className='mt-3'>
								<span className='me-3'>
									{json.DatiFornitura[index].TipologiaUtenza.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].TipologiaUtenza.Valore}
								</span>
							</div>
						)}
						{json.DatiFornitura[index].TipologiaTariffa && (
							<div className='mt-3 mb-5'>
								<span className='me-3'>
									{json.DatiFornitura[index].TipologiaTariffa.Etichetta}
								</span>
								<span className='fw-bold d-block d-md-inline'>
									{json.DatiFornitura[index].TipologiaTariffa.Valore}
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Supply;
