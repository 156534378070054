import agent from '../../../../../api/agent';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import MobileHomepageButton from './MobileHomepageButton';

type Props = {
	json: BollettaJSON;
	backToHomepage: () => void;
};

const Counter = ({ json, backToHomepage }: Props) => {
	const urlClick = (url: string) => {
		agent.Tracking.track('Click link: ' + url, url);
	};

	return (
		<div className='card border-0 mt-md-4 white-card'>
			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex'>
					<MobileHomepageButton backToHomepage={backToHomepage} />
					<span className='h3 ms-3 mt-1'>Sportelli SMAT</span>
				</div>
			</div>
			<div className='main-bg-gradient card-header'></div>
			<div className='px-4 mt-3'>
				<div className='h3 my-2 d-none d-md-block'>Sportelli SMAT</div>
				<div className='d-flex mt-3 mb-4'>
					<div className='mt-3'>
						Nel seguente{' '}
						<a
							className='fw-bold'
							href='https://www.smatorino.it/orari-sportelli/'
							target='_blank'
							onClick={() =>
								urlClick('https://www.smatorino.it/orari-sportelli/')
							}
							rel='noreferrer'
						>
							link
						</a>{' '}
						può trovare la lista degli sportelli SMAT
					</div>
				</div>
			</div>
		</div>
	);
};

export default Counter;
