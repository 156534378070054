import { useState } from 'react';

import { Contratto } from '../models/jsonVeritas';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import Emptyings from './Emptyings';

type Props = {
	contratto: Contratto;
	hideHR?: boolean;
};

const ContractDetail = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromContractDetailComponent}`
		);

		setShowDetails((prev) => !prev);
	};

	return (
		<div id='contract-detail'>
			<div className='d-none d-sm-block'>
				<div className='row pb-4'>
					<div className='col-12 pb-5'>
						{!props.hideHR && <hr style={{ backgroundColor: '#e7f0fe' }} />}
					</div>
					<div className='col-6'>
						<div className='ms-5 me-1'>
							<div className='card app-card-with-background-light-blue p-5'>
								<h4 style={{ textTransform: 'uppercase' }}>
									{props.contratto.NumeroContratto.Etichetta}
									{props.contratto.NumeroContratto.Valore}
								</h4>
								<div className='mt-3 mt-md-4 app-contract-detail-info'>
									<span>{props.contratto.Indirizzo.Etichetta}</span>
									<span className='app-bold'>
										{props.contratto.Indirizzo.Valore}
									</span>
								</div>
								<div className='mt-3 app-contract-detail-info'>
									<span>{props.contratto.TipologiaTariffa.Etichetta}</span>
									<span className='app-bold'>
										{props.contratto.TipologiaTariffa.Valore}
									</span>
								</div>
								{props.contratto.DettagliRiduzioni.map((item, index) => {
									return (
										<div key={index} className='mt-3 app-contract-detail-info'>
											<span>{item.Etichetta}</span>
											<span className='app-bold'>{item.Valore}</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className='col-6'>
						{props.contratto.DettagliAvviso && (
							<div className='me-5 ms-1'>
								<div className='card app-card-with-background-light-blue p-5'>
									<h4 style={{ textTransform: 'uppercase' }}>
										DETTAGLI AVVISO
									</h4>
									<div>
										<p>
											{props.contratto.DocumentoPeriodo.Etichetta}:{' '}
											{props.contratto.DocumentoPeriodo.Valore}
										</p>
									</div>
									{props.contratto.DettagliAvviso.DettagliImmobile &&
										props.contratto.DettagliAvviso.DettagliImmobile.length >
											0 && (
											<div className='table-responsive mt-2'>
												<table className='table table-borderless mb-0'>
													<thead>
														<tr>
															<th scope='col' className='ps-0'>
																{
																	props.contratto.DettagliAvviso
																		.DettagliImmobile[0].Destinazione.Etichetta
																}
															</th>
															<th scope='col'>
																{
																	props.contratto.DettagliAvviso
																		.DettagliImmobile[0].Componenti.Etichetta
																}
															</th>
															<th scope='col' className='pe-0'>
																{
																	props.contratto.DettagliAvviso
																		.DettagliImmobile[0].Metratura.Etichetta
																}
															</th>
														</tr>
													</thead>
													<tbody>
														{props.contratto.DettagliAvviso.DettagliImmobile.map(
															(dest, index) => (
																<tr key={index}>
																	<td className='ps-0 pb-0'>
																		{dest.Destinazione.Valore}
																	</td>
																	<td className='pb-0'>
																		{dest.Componenti.Valore}
																	</td>
																	<td className='pb-0 pe-0'>
																		{dest.Metratura.Valore}
																	</td>
																</tr>
															)
														)}
													</tbody>
												</table>
											</div>
										)}

									{(props.contratto.DettagliAvviso.VariazioniComponenti ||
										props.contratto.DettagliAvviso.VariazioniMetratura ||
										props.contratto.DettagliAvviso.VariazioniDestinazione) && (
										<div className='mt-4'>
											{props.contratto.DettagliAvviso.VariazioniComponenti && (
												<p className='app-only-bold mb-1'>
													{
														props.contratto.DettagliAvviso.VariazioniComponenti
															.Valore
													}
												</p>
											)}
											{props.contratto.DettagliAvviso.VariazioniMetratura && (
												<p className='app-only-bold mb-1'>
													{
														props.contratto.DettagliAvviso.VariazioniMetratura
															.Valore
													}
												</p>
											)}
											{props.contratto.DettagliAvviso
												.VariazioniDestinazione && (
												<p className='app-only-bold mb-1'>
													{
														props.contratto.DettagliAvviso
															.VariazioniDestinazione.Valore
													}
												</p>
											)}
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				{props.contratto.DatiCatastali &&
					props.contratto.DatiCatastali.length > 0 && (
						<div className='row pb-4'>
							<div className='col-12'>
								<div className='ms-5 me-5'>
									<div className='card app-card-with-background-light-blue p-5'>
										<h4>DATI CATASTALI</h4>
										<div className='table-responsive'>
											<table className='table table-borderless mb-0'>
												<thead>
													<tr>
														<th scope='col' className='ps-0'>
															{
																props.contratto.DatiCatastali[0].DestUso
																	.Etichetta
															}
														</th>
														<th scope='col'>
															{
																props.contratto.DatiCatastali[0].SezUrbana
																	.Etichetta
															}
														</th>
														<th scope='col'>
															{
																props.contratto.DatiCatastali[0].Foglio
																	.Etichetta
															}
														</th>
														<th scope='col'>
															{
																props.contratto.DatiCatastali[0].Particella
																	.Etichetta
															}
														</th>
														<th scope='col'>
															{
																props.contratto.DatiCatastali[0].Subalterno
																	.Etichetta
															}
														</th>
														<th scope='col' className='pe-0'>
															{
																props.contratto.DatiCatastali[0].CodCatastale
																	.Etichetta
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{props.contratto.DatiCatastali.map((item, index) => {
														return (
															<tr key={index}>
																<td className='ps-0'>{item.DestUso.Valore}</td>
																<td>{item.SezUrbana.Valore}</td>
																<td>{item.Foglio.Valore}</td>
																<td>{item.Particella.Valore}</td>
																<td>{item.Subalterno.Valore}</td>
																<td className='pe-0'>
																	{item.CodCatastale.Valore}
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				{(!props.contratto.DatiCatastali ||
					props.contratto.DatiCatastali?.length < 1) && (
					<div className='row pb-5'>
						<div className='col-12'>
							<div className='ms-5 me-5'>
								<div className='card app-card-with-background-light-blue p-5'>
									<h4>DATI CATASTALI</h4>
									<p>
										Dati catastali mancanti, chiama il nostro Contact Center al
										nr. verde 800.466.466 (da rete fissa) oppure allo
										041.96.555.30 (da rete mobile) per comunicarli. Grazie
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{props.contratto.DettaglioSvuotamenti && (
					<div className='row pb-5'>
						<div className='col-6'>
							{props.contratto.DettaglioSvuotamenti &&
								props.contratto.DettaglioSvuotamenti.length > 0 && (
									<div className='ms-5 me-1'>
										<div className='card app-card-with-background-light-blue p-5'>
											<h4>DISPOSITIVI E DETTAGLIO SVUOTAMENTI</h4>
											{props.contratto.DettaglioSvuotamenti.map(
												(dettaglioSvuotamento, i) => {
													return (
														<div key={i} className='mt-3'>
															{i !== 0 && (
																<div className='pb-3'>
																	<hr style={{ backgroundColor: '#e7f0fe' }} />
																</div>
															)}
															{dettaglioSvuotamento.Svuotamenti.map(
																(svuotamento, index) => (
																	<Emptyings
																		key={index}
																		svuotamento={svuotamento}
																	/>
																)
															)}
															<div className='mt-2'>
																<div className='app-only-bold'>
																	{
																		dettaglioSvuotamento.TotaliSvuotamenti
																			.Etichetta
																	}
																	{
																		dettaglioSvuotamento.TotaliSvuotamenti
																			.Valore
																	}
																</div>
																{dettaglioSvuotamento.TotaliSvuotamentiMinimi && (
																	<div className='app-only-bold'>
																		{
																			dettaglioSvuotamento
																				.TotaliSvuotamentiMinimi.Etichetta
																		}
																		{
																			dettaglioSvuotamento
																				.TotaliSvuotamentiMinimi.Valore
																		}
																	</div>
																)}
															</div>
														</div>
													);
												}
											)}
										</div>
									</div>
								)}
						</div>
					</div>
				)}
			</div>

			<div className='row d-block d-sm-none'>
				<div className='col-12'>
					<div className='m-2 mb-4'>
						<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3'>
							<div className='app-toggle-show' onClick={toggleDetails}>
								<h4 className='mb-0'>
									CONTRATTO {props.contratto.NumeroContratto.Valore}
								</h4>
								<i
									className={
										showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'
									}
									style={{ fontSize: '1.5rem' }}
								></i>
							</div>
						</div>
						{showDetails && (
							<div className='card app-card-with-background p-5'>
								<div className='app-info-detail'>
									<span className='text-uppercase me-2'>
										{props.contratto.Indirizzo.Etichetta}
									</span>
									<span className='app-only-bold text-end'>
										{props.contratto.Indirizzo.Valore}
									</span>
								</div>
								<div className='app-info-detail'>
									<span className='text-uppercase me-2'>
										{props.contratto.TipologiaTariffa.Etichetta}
									</span>
									<span className='app-only-bold text-end'>
										{props.contratto.TipologiaTariffa.Valore}
									</span>
								</div>
								{props.contratto.DettagliRiduzioni.map((item, index) => (
									<div key={index} className='app-info-detail'>
										<span className='text-uppercase me-2'>
											{item.Etichetta}
										</span>
										<span className='app-only-bold text-end'>
											{item.Valore}
										</span>
									</div>
								))}
								{props.contratto.DettagliAvviso &&
									props.contratto.DettagliAvviso.DettagliImmobile &&
									props.contratto.DettagliAvviso.DettagliImmobile.length >
										0 && (
										<div>
											<hr />
											{props.contratto.DettagliAvviso.DettagliImmobile.map(
												(item, index) => (
													<div key={index}>
														<div className='app-info-detail'>
															<span className='me-2'>
																{item.Destinazione.Etichetta}
															</span>
															<span className='app-only-bold text-end'>
																{item.Destinazione.Valore}
															</span>
														</div>
														<div className='app-info-detail'>
															<span className='me-2'>
																{item.Componenti.Etichetta}
															</span>
															<span className='app-only-bold text-end'>
																{item.Componenti.Valore}
															</span>
														</div>
														<div className='app-info-detail'>
															<span className='me-2'>
																{item.Metratura.Etichetta}
															</span>
															<span className='app-only-bold text-end'>
																{item.Metratura.Valore}
															</span>
														</div>
													</div>
												)
											)}
											{(props.contratto.DettagliAvviso.VariazioniComponenti ||
												props.contratto.DettagliAvviso.VariazioniMetratura ||
												props.contratto.DettagliAvviso
													.VariazioniDestinazione) && (
												<div>
													{props.contratto.DettagliAvviso
														.VariazioniComponenti && (
														<div className='app-info-detail'>
															<span className='me-2'>
																{
																	props.contratto.DettagliAvviso
																		.VariazioniComponenti.Valore
																}
															</span>
														</div>
													)}
													{props.contratto.DettagliAvviso
														.VariazioniMetratura && (
														<div className='app-info-detail'>
															<span className='me-2'>
																{
																	props.contratto.DettagliAvviso
																		.VariazioniMetratura.Valore
																}
															</span>
														</div>
													)}
													{props.contratto.DettagliAvviso
														.VariazioniDestinazione && (
														<div className='app-info-detail'>
															<span className='me-2'>
																{
																	props.contratto.DettagliAvviso
																		.VariazioniDestinazione.Valore
																}
															</span>
														</div>
													)}
												</div>
											)}
										</div>
									)}

								{props.contratto.DatiCatastali &&
									props.contratto.DatiCatastali.length > 0 && (
										<div>
											<hr />
											{props.contratto.DatiCatastali.map((item, index) => (
												<div key={index}>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.DestUso.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.DestUso.Valore}
														</span>
													</div>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.SezUrbana.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.SezUrbana.Valore}
														</span>
													</div>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.Foglio.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.Foglio.Valore}
														</span>
													</div>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.Particella.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.Particella.Valore}
														</span>
													</div>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.Subalterno.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.Subalterno.Valore}
														</span>
													</div>
													<div className='app-info-detail'>
														<span className='me-2'>
															{item.CodCatastale.Etichetta}
														</span>
														<span className='app-only-bold text-end'>
															{item.CodCatastale.Valore}
														</span>
													</div>
												</div>
											))}
										</div>
									)}
								{(!props.contratto.DatiCatastali ||
									props.contratto.DatiCatastali?.length < 1) && (
									<div>
										<hr />
										<p>
											Dati catastali mancanti, chiama il nostro Contact Center
											al nr. verde 800.466.466 (da rete fissa) oppure allo
											041.96.555.30 (da rete mobile) per comunicarli. Grazie
										</p>
									</div>
								)}

								{props.contratto.DettaglioSvuotamenti &&
									props.contratto.DettaglioSvuotamenti.length > 0 && (
										<div>
											<hr />
											{props.contratto.DettaglioSvuotamenti.map(
												(dettaglioSvuotamento, index) => (
													<div key={index}>
														{dettaglioSvuotamento.Svuotamenti.map(
															(svuotamento, index) => (
																<Emptyings
																	key={index}
																	svuotamento={svuotamento}
																/>
															)
														)}
														<div className='app-info-detail'>
															<span className='me-2 text-uppercase'>
																{
																	dettaglioSvuotamento.TotaliSvuotamenti
																		.Etichetta
																}
															</span>
															<span className='app-only-bold text-end'>
																{dettaglioSvuotamento.TotaliSvuotamenti.Valore}
															</span>
														</div>
														{dettaglioSvuotamento.TotaliSvuotamentiMinimi && (
															<div className='app-info-detail'>
																<span className='me-2 text-uppercase'>
																	{
																		dettaglioSvuotamento.TotaliSvuotamentiMinimi
																			.Etichetta
																	}
																</span>
																<span className='app-only-bold text-end'>
																	{
																		dettaglioSvuotamento.TotaliSvuotamentiMinimi
																			.Valore
																	}
																</span>
															</div>
														)}
													</div>
												)
											)}
										</div>
									)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractDetail;
