import { useParams, useRouteLoaderData } from 'react-router-dom';
import { BollettaJSON } from '../models/jsonSmatIdrico';
import Summary from './Summary';
import Supply from './Supply';
import Consumption from './Consumption';
import Counter from './Counter';
import Amounts from './Amounts';
import Details from './Details';
import ConsumptionReadings from './ConsumptionReadings';
import MobileMenu from './MobileMenu';
import { useState } from 'react';
import NewCustomerButton from './NewCustomerButton';
import Utilities from './Utilities';
import Payment from './Payment';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';

const Document = () => {
	const json = useRouteLoaderData('smatIdricoJsonLoader') as BollettaJSON;
	const { id } = useParams();

	const [selectedContract, setSelectedContract] = useState(0);
	const [currentView, setCurrentView] = useState(1);
	const goToHomepage = () => {
		agent.Tracking.track(Static.smatHomepageButtonClick);
		setCurrentView(1);
	};

	const menuBgColor =
		currentView === 1
			? 'main-bg-gradient'
			: currentView === 2
			? 'bg-white'
			: currentView === 3
			? 'blue-bg'
			: 'bg-white';
	const summaryBgColor = 'main-bg-color';
	const utilitiesBgColor = 'bg-white';
	const supplyBgColor = 'blue-bg';
	const consumptionBgColor = 'bg-white';
	const amountsBgColor = 'blue-bg';
	const consumptionReadingsBgColor = 'bg-white';
	const detailsBgColor = 'main-bg-color';

	const isNewCustomer = json.NuovoCliente;

	return (
		<>
			{isNewCustomer && <NewCustomerButton documentId={Number(id)} />}
			<div className={`d-md-none d-block ${isNewCustomer ? 'darker' : ''}`}>
				<div className={menuBgColor}>
					<MobileMenu json={json} setCurrentView={setCurrentView} />
				</div>
				<div className={summaryBgColor}>
					{currentView === 1 && (
						<>
							<div className={summaryBgColor}>
								<Summary json={json} />
							</div>
						</>
					)}
				</div>
				{currentView === 3 && (
					<>
						<div className={utilitiesBgColor}>
							<Utilities
								json={json}
								backToHomepage={goToHomepage}
								setCurrentContract={setSelectedContract}
								currentContract={selectedContract}
							/>
						</div>
						<div className={supplyBgColor}>
							<Supply
								json={json}
								backToHomepage={goToHomepage}
								index={selectedContract}
							/>
						</div>
						<div className={consumptionBgColor}>
							<Consumption
								json={json}
								backToHomepage={goToHomepage}
								index={selectedContract}
							/>
						</div>
						<div className={amountsBgColor}>
							<Amounts json={json} backToHomepage={goToHomepage} />
						</div>
						<div className={consumptionReadingsBgColor}>
							<ConsumptionReadings
								json={json}
								backToHomepage={goToHomepage}
								index={selectedContract}
							/>
						</div>
						<div className={detailsBgColor}>
							<Details
								json={json}
								backToHomepage={goToHomepage}
								index={selectedContract}
							/>
						</div>
					</>
				)}
				{currentView === 4 && (
					<Counter json={json} backToHomepage={goToHomepage} />
				)}
			</div>
			<div
				className={`mx-4 d-md-block d-none ${isNewCustomer ? 'darker' : ''}`}
			>
				<Summary json={json} />
				<Payment json={json} backToHomepage={goToHomepage} />
				<Utilities
					json={json}
					backToHomepage={goToHomepage}
					setCurrentContract={setSelectedContract}
					currentContract={selectedContract}
				/>
				<div className='row'>
					<div className='col-6'>
						<Supply
							json={json}
							backToHomepage={goToHomepage}
							index={selectedContract}
						/>
						<Counter json={json} backToHomepage={goToHomepage} />
						<Details
							json={json}
							backToHomepage={goToHomepage}
							index={selectedContract}
						/>
					</div>
					<div className='col-6'>
						<Consumption
							json={json}
							backToHomepage={goToHomepage}
							index={selectedContract}
						/>
						<Amounts json={json} backToHomepage={goToHomepage} />
					</div>
				</div>
				<ConsumptionReadings
					json={json}
					backToHomepage={goToHomepage}
					index={selectedContract}
				/>
			</div>
		</>
	);
};

export default Document;
