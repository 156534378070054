import QRCode from 'react-qr-code';

import { BollettaJSON } from '../models/jsonSmatIdrico';
import agent from '../../../../../api/agent';
import CopyButton from './CopyButton';
import { Static } from '../../../../../static';

type Props = {
	json: BollettaJSON;
	backToHomepage: () => void;
};

const Payment = ({ json, backToHomepage }: Props) => {
	const cbillPaymentMethod = json.Pagamenti.Metodi.length
		? json.Pagamenti.Metodi[1] ?? null
		: null;

	const pagopaPaymentMethod = json.Pagamenti.Metodi.length
		? json.Pagamenti.Metodi[0] ?? null
		: null;

	const qrCodeValue = pagopaPaymentMethod
		? `PAGOPA|002|${pagopaPaymentMethod.CodiceAvviso ?? ''}|${
				pagopaPaymentMethod.PartitaIva
		  }|${pagopaPaymentMethod.Importo * 100}`
		: '';

	const anyContent =
		!json.Pagamenti.DomiciliatoSEPA ||
		cbillPaymentMethod ||
		pagopaPaymentMethod;

	const payNowLinkRedirect = () => {
		agent.Tracking.track(
			Static.smatExternalLinkClick,
			Static.smatPagoPaDatiAvviso
		);
		window.open(Static.smatPagoPaDatiAvviso, '_blank');
	};

	const goToOnlineDesk = () => {
		agent.Tracking.track(
			Static.smatOnlineDeskExternalLinkClick,
			Static.urlSmatOnlineDesk
		);
		window.open(Static.urlSmatOnlineDesk, '_blank');
	};

	const goToAllegato = () => {
		agent.Tracking.track(
			Static.smatAttachmentExternalLinkClick,
			json.Pagamenti.Allegato
		);
		window.open(json.Pagamenti.Allegato, '_blank');
	};

	const mailToSmatContratti = () => {
		agent.Tracking.track(Static.smatMailToContracts, 'contratti@smatorino.it');
		window.open('mailto:contratti@smatorino.it', '_blank');
	};

	// const mailToSmatInfo = () => {
	// 	agent.Tracking.track('SMAT MailTo info@smatorino.it', 'info@smatorino.it');
	// 	window.open('mailto:info@smatorino.it', '_blank');
	// };

	// TODO : New specs via mail

	return anyContent ? (
		<div>
			{/* Mobile */}
			<div className='d-block d-md-none'>
				{!json.Pagamenti.DomiciliatoSEPA && (
					<>
						<div className='h3 text-center'>
							DOMICILIAZIONE BANCARIA/POSTALE
						</div>
						<div className='mt-3 mb-4 text-center'>
							<p>
								Le segnaliamo che la sua bolletta non è ancora domiciliata in
								banca o in posta.
							</p>
							<p>
								Per agevolare e semplificare il processo di pagamento delle
								fatture, ti invitiamo a considerare l'attivazione della
								domiciliazione bancaria (SDD): è la modalità di pagamento più
								semplice per l’utente ed è assolutamente gratuita.
							</p>
							<p>
								Per attivare la domiciliazione bancaria o postale basterà
								scegliere una delle seguenti opzioni:
							</p>
							<ol>
								<li>
									Rivolgersi al proprio Istituto Bancario utilizzando i codici
									di riferimento (CID - SEPA - MANDATO) riportati in bolletta;
								</li>
								<li>
									Compilare l'apposito modulo disponibile alla pagina{' '}
									<span className='link-style' onClick={goToAllegato}>
										{json.Pagamenti.Allegato}
									</span>{' '}
									e consegnarlo presso uno dei nostri sportelli: per posta,
									all’indirizzo Corso XI Febbraio, 14 - 10152 Torino; per fax al
									numero 011 43 65 575 oppure per email a{' '}
									<span className='link-style' onClick={mailToSmatContratti}>
										contratti@smatorino.it
									</span>
									;
								</li>
								<li>
									Utilizzando l’apposito servizio disponibile sullo{' '}
									<span className='link-style' onClick={goToOnlineDesk}>
										Sportello Online
									</span>{' '}
									previa registrazione
								</li>
							</ol>
						</div>
					</>
				)}
				{(pagopaPaymentMethod != null || cbillPaymentMethod != null) && (
					<>
						<div className='h3 text-center'>PAGAMENTO</div>
						<div className='ms-4 mt-1'>
							E' possibile procedere col pagamento con dei seguenti canali messi
							a disposizione:
							<ul>
								<li>
									Circuito CBILL: scegliendo SMAT – SOCIETA’ METROPOLITANA ACQUE
									TORINO SPA e inserendo il codice a 18 cifre riportato nella
									sezione “C-BILL”
								</li>
								<li>
									Canale PagoPA: accessibile tramite home banking del proprio
									ente finanziario (banca, Poste Italiane), i punti vendita
									Lottomatica (tabaccherie, ricevitorie del Lotto) o qualsiasi
									servizio autorizzato (Satispay). Inquadra direttamente il
									QRCODE oppure inserisci il codice avviso PagoPA e il codice
									fiscale dell'ente creditore riportati nella sezione dedicata.
								</li>
							</ul>
						</div>
					</>
				)}
				{pagopaPaymentMethod && (
					<div>
						<div className='text-center mt-3'>
							<img
								alt=''
								className='responsive-image'
								src='/images/smat/invoice/logo-pagoPA.svg'
							/>
						</div>
						<div className='text-center mt-3'>
							<QRCode value={qrCodeValue} size={130} />
							<div className='mt-2'>
								<div>Cod. CBILL</div>
								<div className='fw-bold'>
									{pagopaPaymentMethod.CodiceSia}
									<CopyButton textToCopy={pagopaPaymentMethod.CodiceSia} />
								</div>
							</div>
							<div>
								<div>Codice Avviso</div>
								<div className='fw-bold'>
									{pagopaPaymentMethod.CodiceAvviso}
									<CopyButton textToCopy={pagopaPaymentMethod.CodiceAvviso} />
								</div>
							</div>
							<div>
								<div>Cod. fiscale ente</div>
								<div className='fw-bold'>
									{pagopaPaymentMethod.PartitaIva}
									<CopyButton textToCopy={pagopaPaymentMethod.PartitaIva} />
								</div>
							</div>
						</div>
						<div className='text-center mt-3'>
							<button
								className='btn main-button px-4 py-2'
								onClick={payNowLinkRedirect}
							>
								Altri canali
							</button>
						</div>
						<div className='text-center mt-1'>
							<img
								alt=''
								className='icon-payment-image mx-2'
								src='/images/smat/invoice/paypal.svg'
							/>
							<img
								alt=''
								className='icon-payment-image mx-2'
								src='/images/smat/invoice/visa.svg'
							/>
							<img
								alt=''
								className='icon-payment-image mx-2'
								src='/images/smat/invoice/mastercard.svg'
							/>
							<img
								alt=''
								className='icon-payment-image mx-2'
								src='/images/smat/invoice/amex.svg'
							/>
						</div>
						<hr className='mt-3' />
					</div>
				)}
				{cbillPaymentMethod && (
					<div>
						<div className='mt-3'>
							<div className='fw-bold h3 text-center'>
								<img
									alt=''
									className='responsive-image'
									src='/images/smat/invoice/cbill-logo.png'
								/>
							</div>
							<div className='ms-3 fw-bold h4 text-center'>
								{cbillPaymentMethod.CodiceAvviso}
								<CopyButton textToCopy={cbillPaymentMethod.CodiceAvviso} />
							</div>
						</div>
					</div>
				)}
			</div>
			{/* Desktop */}
			<div className='d-none d-md-block'>
				<div className='card border-0 mt-lg-4 white-card border-radius pb-5'>
					<div className='main-bg-gradient card-header'></div>

					<div className='mt-4 ms-4'>
						{!json.Pagamenti.DomiciliatoSEPA && (
							<>
								<div className='h3 my-2'>DOMICILIAZIONE BANCARIA/POSTALE</div>
								<div>
									<p>
										Le segnaliamo che la sua bolletta non è ancora domiciliata
										in banca o in posta.
									</p>
									<p>
										Per agevolare e semplificare il processo di pagamento delle
										fatture, ti invitiamo a considerare l'attivazione della
										domiciliazione bancaria (SDD): è la modalità di pagamento
										più semplice per l’utente ed è assolutamente gratuita.
									</p>
									<p>
										Per attivare la domiciliazione bancaria o postale basterà
										scegliere una delle seguenti opzioni:
									</p>
									<ol>
										<li>
											Rivolgersi al proprio Istituto Bancario utilizzando i
											codici di riferimento (CID - SEPA - MANDATO) riportati in
											bolletta;
										</li>
										<li>
											Compilare l'apposito modulo disponibile alla pagina{' '}
											<span className='link-style' onClick={goToAllegato}>
												{json.Pagamenti.Allegato}
											</span>{' '}
											e consegnarlo presso uno dei nostri sportelli: per posta,
											all’indirizzo Corso XI Febbraio, 14 - 10152 Torino; per
											fax al numero 011 43 65 575 oppure per email a{' '}
											<span
												className='link-style'
												onClick={mailToSmatContratti}
											>
												contratti@smatorino.it
											</span>
											;
										</li>
										<li>
											Utilizzando l’apposito servizio disponibile sullo{' '}
											<span className='link-style' onClick={goToOnlineDesk}>
												Sportello Online
											</span>{' '}
											previa registrazione
										</li>
									</ol>
								</div>
							</>
						)}
					</div>
					{(pagopaPaymentMethod != null || cbillPaymentMethod != null) && (
						<>
							<div className='h3 my-2 mt-4 ms-4'>PAGAMENTO</div>
							<div className='ms-4 mt-1'>
								E' possibile procedere col pagamento con dei seguenti canali
								messi a disposizione:
								<ul>
									<li>
										Circuito CBILL: scegliendo SMAT – SOCIETA’ METROPOLITANA
										ACQUE TORINO SPA e inserendo il codice a 18 cifre riportato
										nella sezione “C-BILL”
									</li>
									<li>
										Canale PagoPA: accessibile tramite home banking del proprio
										ente finanziario (banca, Poste Italiane), i punti vendita
										Lottomatica (tabaccherie, ricevitorie del Lotto) o qualsiasi
										servizio autorizzato (Satispay). Inquadra direttamente il
										QRCODE oppure inserisci il codice avviso PagoPA e il codice
										fiscale dell'ente creditore riportati nella sezione
										dedicata.
									</li>
									<li>
										PagoPA web: clicca su "PagoPA web" per eseguire il pagamento
										con carta di credito/debito o paypal tramite canale PagoPA".
									</li>
								</ul>
							</div>
						</>
					)}
					{cbillPaymentMethod && (
						<div>
							<div className='mt-4 ms-4'>
								<img
									alt=''
									className='responsive-image'
									src='/images/smat/invoice/cbill-logo.png'
								/>
								<span className='ms-3 fw-bold h4'>
									{cbillPaymentMethod.CodiceAvviso}
									<CopyButton textToCopy={cbillPaymentMethod.CodiceAvviso} />
								</span>
							</div>
							<hr className='mt-4' />
						</div>
					)}
					{pagopaPaymentMethod && pagopaPaymentMethod.DataScadenza && (
						<div className='text-end me-3'>
							<span>Rata unica entro il</span>
							<span className='ms-1 fw-bold'>
								{pagopaPaymentMethod.DataScadenza || ''}
							</span>
						</div>
					)}
					{pagopaPaymentMethod && (
						<div>
							<div className='my-3 mx-5 d-flex justify-content-between'>
								<div>
									<img
										alt=''
										className='responsive-image'
										src='/images/smat/invoice/logo-pagoPA.svg'
									/>
								</div>
								<div className='text-end mt-auto'>
									<button
										className='btn main-button px-4 py-2'
										onClick={payNowLinkRedirect}
									>
										Altri canali
									</button>
								</div>
							</div>
							<div className='text-end'>
								<img
									alt=''
									className='icon-payment-image mx-2'
									src='/images/smat/invoice/paypal.svg'
								/>
								<img
									alt=''
									className='icon-payment-image mx-2'
									src='/images/smat/invoice/visa.svg'
								/>
								<img
									alt=''
									className='icon-payment-image mx-2'
									src='/images/smat/invoice/mastercard.svg'
								/>
								<img
									alt=''
									className='icon-payment-image mx-2'
									src='/images/smat/invoice/amex.svg'
								/>
							</div>
							<div className='row px-5 mt-4'>
								<div className='col-lg-4'>
									<QRCode value={qrCodeValue} size={200} />
									<div className='d-lg-none d-block'>
										Inquadra il <span className='fw-bold'>codice QR</span> con
										la tua app di pagamento o usa i dati accanto
									</div>
								</div>
								<div className='col-lg-4'>
									<div>
										<div>Destinatario</div>
										<div className='fw-bold'>
											{json.Riepilogo.DenominazioneCliente}
										</div>
									</div>
									<div className='mt-2'>
										<div>Ente Creditore</div>
										<div className='fw-bold'>{'SMA TORINO S.P.A.'}</div>
									</div>
									<div className='mt-2'>
										<div>Oggetto del pagamento</div>
										<div className='fw-bold'>
											{pagopaPaymentMethod.OggettoPagamento || ''}
										</div>
									</div>
								</div>
								<div className='col-lg-4 text-lg-end'>
									EURO
									<span className='fw-bold h3 ms-2'>
										{pagopaPaymentMethod.Importo}
									</span>
								</div>
							</div>
							<div className='mt-3 d-flex justify-content-between px-5'>
								<div className='d-lg-block d-none'>
									Inquadra il <span className='fw-bold'>codice QR</span> con la
									tua app di
									<br /> pagamento o usa i dati accanto
								</div>
								<div>
									<div>Cod. CBILL</div>
									<div className='fw-bold'>
										{pagopaPaymentMethod.CodiceSia || ''}
										<CopyButton textToCopy={pagopaPaymentMethod.CodiceSia} />
									</div>
								</div>
								<div>
									<div>Codice Avviso</div>
									<div className='fw-bold'>
										{pagopaPaymentMethod.CodiceAvviso}
										<CopyButton textToCopy={pagopaPaymentMethod.CodiceAvviso} />
									</div>
								</div>
								<div>
									<div>Cod. fiscale ente</div>
									<div className='fw-bold'>
										{pagopaPaymentMethod.PartitaIva}
										<CopyButton textToCopy={pagopaPaymentMethod.PartitaIva} />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default Payment;
