import { useFormikContext } from 'formik';

import MyTextInput from '../../../../components/MyTextInput';

type Props = { privacyClick: () => void; disabled: boolean };

const MainForm = (props: Props) => {
	const { handleSubmit, isSubmitting, isValid, dirty } =
		useFormikContext<any>();
	return (
		<form onSubmit={handleSubmit}>
			<h6 className='text-white text-md-start text-center'>
				DA COMPILARE IN CASO DI VENDITA VEICOLO NUOVO INTESTATARIO
			</h6>
			<div className='row'>
				<div className='mt-3 col-md-6'>
					<div className=''>
						<MyTextInput
							disabled={props.disabled}
							type='text'
							className='form-control'
							placeholder='Cognome'
							name='surname'
						/>
					</div>
				</div>
				<div className='mt-3 col-md-6'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='Nome'
						name='name'
					/>
				</div>
			</div>
			<div className='row'>
				<div className='mt-3 col-md-8'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='Indirizzo'
						name='address'
					/>
				</div>
				<div className='mt-3 col-md-4 col-6'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='N°'
						name='streetNumber'
					/>
				</div>
				<div className='mt-3 col-md-4 col-6'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='Cap'
						name='cap'
					/>
				</div>
				<div className='mt-3 col-md-4'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='Località'
						name='city'
					/>
				</div>
				<div className='mt-3 col-md-4'>
					<MyTextInput
						disabled={props.disabled}
						type='text'
						className='form-control'
						placeholder='Provincia'
						name='province'
					/>
				</div>
			</div>
			<div className='my-2'>
				<i className='text-white' style={{ fontSize: '0.8rem' }}>
					Cliccando sul pulsante "Conferma" dichiaro di aver letto e accettato
					le condizioni sul trattamento dei dati. Per maggiori informazioni
					clicca su{' '}
					<i
						className='text-decoration-underline pointer'
						onClick={props.privacyClick}
					>
						INFORMATIVA PRIVACY
					</i>
				</i>
			</div>
			<div className='col-md-2 d-flex mt-4'>
				<button
					className='btn flex-grow-1'
					type='submit'
					disabled={isSubmitting || !isValid || !dirty || props.disabled}
				>
					Conferma
				</button>
			</div>
		</form>
	);
};

export default MainForm;
