import { useEffect, useState } from 'react';

import { BollettaJSON } from '../models/jsonSmatIdrico';
import CollapseButton from './CollapseButton';

type Props = {
	index: number;
	json: BollettaJSON;
	backToHomepage: () => void;
};

const Consumption = ({ json, backToHomepage, index }: Props) => {
	const [collapseShowing, setCollapseShowing] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) setIsMobile(true);
			else setIsMobile(false);
		};
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='card border-0 mt-md-4 white-to-blue-card border-radius'>
			<div className='main-bg-gradient card-header'></div>

			<div className='d-flex d-md-none justify-content-between mt-3'>
				<div className='d-flex ms-3'>
					<span className='h3 ms-5 ps-2 mt-1'>IL MIO CONSUMO</span>
				</div>
				<CollapseButton
					section='Consumption'
					toggleFunction={setCollapseShowing}
					color='white'
					showing={collapseShowing}
				/>
			</div>
			<div
				className={`px-4 mt-3 collapse ${
					!isMobile ? 'show' : collapseShowing && 'show'
				}`}
			>
				<div className='h3 my-2 d-none d-md-block'>IL MIO CONSUMO</div>
				{json.DatiFornitura[index]?.Consumo && (
					<div className='mt-3 h1 fw-bold'>
						{json.DatiFornitura[index]?.Consumo.Valore}{' '}
						{json.DatiFornitura[index]?.Consumo.Etichetta}
					</div>
				)}
				{json.DatiFornitura[index]?.UltimaLettura && (
					<div className='mt-2'>
						{json.DatiFornitura[index]?.UltimaLettura.Etichetta}{' '}
						{json.DatiFornitura[index]?.UltimaLettura.Valore}
					</div>
				)}
				{json.DatiFornitura[index]?.MatricolaContatore && (
					<div className='mt-3 mt-md-5'>
						<span className='me-3'>
							{json.DatiFornitura[index]?.MatricolaContatore.Etichetta}
						</span>
						<span className='fw-bold d-block d-md-inline'>
							{json.DatiFornitura[index]?.MatricolaContatore.Valore}
						</span>
					</div>
				)}
				{json.DatiFornitura[index]?.AccesibilitaMisuratore && (
					<div className='mt-3'>
						<span className='me-3'>
							{json.DatiFornitura[index]?.AccesibilitaMisuratore.Etichetta}
						</span>
						<span className='fw-bold d-block d-md-inline'>
							{json.DatiFornitura[index]?.AccesibilitaMisuratore.Valore}
						</span>
					</div>
				)}
				{json.DatiFornitura[index]?.PreavvisoPassaggio && (
					<div className='mt-3'>
						<span className='me-3'>
							{json.DatiFornitura[index]?.PreavvisoPassaggio.Etichetta}
						</span>
						<span className='fw-bold d-block d-md-inline'>
							{json.DatiFornitura[index]?.PreavvisoPassaggio.Valore}
						</span>
					</div>
				)}
				{json.DatiFornitura[index]?.TentativiMinimi && (
					<div className='mt-3'>
						<span className='me-3'>
							{json.DatiFornitura[index]?.TentativiMinimi.Etichetta}
						</span>
						<span className='fw-bold d-block d-md-inline'>
							{json.DatiFornitura[index]?.TentativiMinimi.Valore}
						</span>
					</div>
				)}
				<div className='mb-5'></div>
			</div>
		</div>
	);
};

export default Consumption;
