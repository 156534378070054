import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';

import { useStore } from '../../context/store';

const ModalContainer = () => {
	const { modalStore } = useStore();

	return (
		<Modal
			// onBackdropClick={(e) => e.preventDefault()}
			closeIcon
			show={modalStore.modal.open}
			onHide={modalStore.closeModal}
			size={modalStore.modal.size}
			style={{ border: 'none' }}
			centered
		>
			<Modal.Body
				className={modalStore.modal.classes}
				style={{ borderRadius: 'inherit' }}
			>
				{modalStore.modal.body}
			</Modal.Body>
		</Modal>
	);
};

export default observer(ModalContainer);
